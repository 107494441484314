import React from "react";

import "./game-preview-icon.styles.scss";

const GamePreviewIcon = ({ game }) => {
  return (
    <div
      className="section-a-game"
    >
      <img src={`${game.thumbnail}`} alt="game" className="circle" />
      <div className="game-name">
        {game.home}&nbsp;vs&nbsp;{game.away}
      </div>
    </div>
  );
};


export default GamePreviewIcon;
