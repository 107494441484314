import h337 from "heatmap.js";
import Rainbow from "rainbowvis.js";

function getFieldFill(rainbow, val) {
  if (typeof val == "object") val = val.value;
  if (val > 0) {
    return "#" + rainbow.colourAt(val);
  } else {
    return "white";
  }
}

function translateDisplay(display_type, val, value_required = null) {
  console.log("TD", val, value_required, display_type);
  if (typeof val == "object") val = val.value;
  if (display_type === "batting_avg" || display_type === "slugging") {
    val = parseFloat(val);
    if (!isNaN(val)) {
      val = val.toFixed(3).replace(/^0+/, "");
    } else if (value_required) {
      val = 0;
    } else {
      val = "";
    }
  }
  return val;
}

function toRadians(deg) {
  return (deg * Math.PI) / 180;
}

function placePitchDot(ctx, x, y, numb, result) {
  var x = parseInt(x);
  var y = parseInt(y);
  ctx.beginPath();
  if (result > 10) {
    ctx.fillStyle = "red";
    ctx.strokeStyle = "red";
  } else {
    ctx.fillStyle = "green";
    ctx.strokeStyle = "green";
  }
  ctx.moveTo(x, y);
  ctx.arc(x, y, 6, 0, Math.PI * 2);
  ctx.stroke();
  ctx.fill();
  ctx.closePath();
  ctx.beginPath();
  ctx.fillStyle = "white";
  ctx.strokeStyle = "white";
  ctx.textAlign = "center";
  ctx.font = "700 10px Helvetica";
  ctx.fillText(numb, x, y + 3);
  ctx.stroke();
  ctx.beginPath();
}

function placePitchDotChart(
  ctx,
  x,
  y,
  numb,
  color,
  sizeArc = 18,
  fontSize = "20px",
  textY = 7
) {
  x = x * 4;
  y = y * 4;
  ctx.beginPath();
  ctx.fillStyle = color;
  ctx.strokeStyle = color;
  ctx.moveTo(x, y);
  ctx.arc(x, y, sizeArc, 0, Math.PI * 2);
  ctx.stroke();
  ctx.fill();
  ctx.closePath();
  ctx.beginPath();
  ctx.fillStyle = "white";
  ctx.strokeStyle = "white";
  ctx.textAlign = "center";
  ctx.font = `${fontSize} Helvetica`;
  ctx.fillText(numb, x, y + textY);
  ctx.stroke();
  ctx.beginPath();
}

function drawDiamond(context, x, y, width, height, box) {
  context.beginPath();
  context.fillStyle = "white";
  context.strokeStyle = "white";
  context.moveTo(x, y);

  // top left edge
  context.lineTo(x - width / 2, y + height / 2);

  // bottom left edge
  context.lineTo(x, y + height);

  // bottom right edge
  context.lineTo(x + width / 2, y + height / 2);

  // closing the path automatically creates
  // the top right edge
  context.closePath();

  if (box) {
    context.fillStyle = "#f05d25";
    context.fill();
  } else {
    context.fillStyle = "rgba(240,93,37,0.33)";
    context.fill();
  }
  context.closePath();
  context.restore();
}

export function showOutsCanvas(ctx, width, height, game_sit) {
  if (!ctx) return;
  ctx.beginPath();
  ctx.clearRect(0, 0, width, height);
  drawDiamond(ctx, 23, 28, 45, 45, game_sit.on_third);
  drawDiamond(ctx, 50, 0, 45, 45, game_sit.on_second);
  drawDiamond(ctx, 78, 28, 45, 45, game_sit.on_first);
  ctx.beginPath();
  ctx.fillStyle = "white";
  ctx.strokeStyle = "white";
  ctx.font = "40px Helvetica-Bold";
  ctx.fillText(game_sit.inning, 50, 100);
  ctx.stroke();
  ctx.restore();
  ctx.beginPath();
  if (game_sit.top) {
    ctx.moveTo(30, 95);
    ctx.lineTo(39, 75);
    ctx.lineTo(50, 95);
  } else {
    ctx.moveTo(30, 85);
    ctx.lineTo(39, 105);
    ctx.lineTo(50, 85);
  }
  ctx.closePath();
  ctx.fill();
  //var canvas = document.getElementById('game-outs-ctx');
  //var ctx = canvas.getContext('2d');
  ctx.beginPath();
  //Display Outs
  ctx.strokeStyle = "rgba(240,93,37,0.52)";
  ctx.beginPath();
  ctx.arc(25, 90, 10, 0, Math.PI * 2);
  ctx.closePath();
  if (game_sit.outs > 0) {
    ctx.fillStyle = "#f05d25";
  } else {
    ctx.fillStyle = "rgba(240,93,37,0.33)";
  }
  ctx.fill();
  ctx.beginPath();
  ctx.arc(50, 90, 10, 0, Math.PI * 2);
  ctx.closePath();

  if (game_sit.outs > 1) {
    ctx.fillStyle = "#f05d25";
  } else {
    ctx.fillStyle = "rgba(240,93,37,0.33)";
  }
  ctx.fill();
  ctx.beginPath();
  ctx.arc(75, 90, 10, 0, Math.PI * 2);
  ctx.closePath();
  if (game_sit.outs > 2) {
    ctx.fillStyle = "#f05d25";
  } else {
    ctx.fillStyle = "rgba(240,93,37,0.33)";
  }
  ctx.fill();
  ctx.restore();
}

export function drawSequenceCanvas(ctx, width, height, data) {
  if (!ctx) return;
  ctx.clearRect(0, 0, width, height);
  var newCoeffX = 250 / width;
  var newCoeffY = 300 / height;
  ctx.strokeStyle = "black";
  ctx.setLineDash([16, 4]);
  ctx.beginPath();
  ctx.strokeRect(
    148 / 2 / newCoeffX,
    176 / 2 / newCoeffY,
    204 / 2 / newCoeffX,
    248 / 2 / newCoeffY
  );
  ctx.closePath();
  var i;
  for (i = 0; i < data.length; i++) {
    var pitch = data[i];
    placePitchDotChart(
      ctx,
      pitch.locx / 2 / newCoeffX,
      pitch.locy / 2 / newCoeffY,
      pitch.seq,
      pitch.color,
      18 / newCoeffX,
      `${20 / newCoeffX}px`,
      7 / newCoeffX
    );
  }
}

export function drawSituationPlayCanvas(ctx, width, height, data) {
  if (!ctx) return;
  var cx = width / 2;
  var cy = height;
  console.log(cy, cx);
  var ytop = 150;
  var fence = 210 * 0.7;
  //ctx.clearRect(0, 0, width, height);
  ctx.strokeStyle = "#777777";
  ctx.beginPath();
  console.log(data);
  //First Base
  if (data.on_first) drawDiamond(ctx, 143, 103, 15, 15, true);
  //Second Base
  if (data.on_second) drawDiamond(ctx, 110, 82, 15, 15, true);
  //Third Base
  if (data.on_third) drawDiamond(ctx, 77, 104, 15, 15, true);
  ctx.font = "14px Helvetica-Bold";
  ctx.textAlign = "center";
  var x = data.x * 0.3;
  var y = data.y * 0.3;
  ctx.moveTo(x, y);
  ctx.fillStyle = "#04204b";
  ctx.strokeStyle = "#04204b";
  ctx.fillText(data.display, x, y);
  //ctx.arc(x, y, 5, 0, Math.PI * 2);
  ctx.stroke();
  ctx.fill();
  ctx.beginPath();
  ctx.strokeStyle = "#f07c00";
  ctx.moveTo(cx, ytop);
  switch (data.flight) {
    case 2:
      ctx.quadraticCurveTo(cx, y * 2, x, y);
      break;
    case 3:
      ctx.quadraticCurveTo(cx, y * 0.01, x, y);
      break;
    default:
      ctx.quadraticCurveTo(x, y, x, y);
  }
  ctx.stroke();

  ctx.beginPath();
  //Display Outs
  ctx.textAlign = "left";
  ctx.fillText("OUTS", 160, 125);
  ctx.fillText(data.inning + " IN", 10, 125);
  if (data.count) {
    ctx.fillText("Count " + data.count, 10, 140);
  }
  ctx.fillStyle = "black";
  ctx.strokeStyle = "black";
  ctx.beginPath();
  ctx.arc(150, 140, 7, 0, Math.PI * 2);
  ctx.closePath();
  if (data.outs > 0) {
    ctx.fill();
  } else {
    ctx.stroke();
  }
  ctx.beginPath();
  ctx.arc(170, 140, 7, 0, Math.PI * 2);
  ctx.closePath();

  if (data.outs > 1) {
    ctx.fill();
  } else {
    ctx.stroke();
  }
  ctx.beginPath();
  ctx.arc(190, 140, 7, 0, Math.PI * 2);
  ctx.closePath();
  if (data.outs > 2) {
    ctx.fill();
  } else {
    ctx.stroke();
  }
  ctx.restore();
}

function drawImageScaled(img, ctx) {
  var canvas = ctx.canvas;
  var hRatio = canvas.width / img.width;
  var vRatio = canvas.height / img.height;
  var ratio = Math.min(hRatio, vRatio);
  var centerShift_x = (canvas.width - img.width * ratio) / 2;
  var centerShift_y = (canvas.height - img.height * ratio) / 2;
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(
    img,
    0,
    0,
    img.width,
    img.height,
    centerShift_x,
    centerShift_y,
    img.width * ratio,
    img.height * ratio
  );
}

export function drawWithBackgroundImage(
  ctx,
  width,
  height,
  backgroundImage,
  func,
  ...props
) {
  ctx.clearRect(0, 0, width, height);

  var img = new Image();
  img.src = backgroundImage;
  img.onload = () => {
    drawImageScaled(img, ctx);
    func(ctx, width, height, ...props);
  };
}

export function drawField(
  ctx,
  width,
  height,
  data,
  live_board,
  backgroundImage
) {
  if (!ctx) return;
  var f = function (ctx, width, height, data, live_board) {
    var f = 1.5;
    var cx = 270 * f;
    ctx.beginPath();
    ctx.fillStyle = "white";
    ctx.strokeStyle = "white";
    ctx.font = "700 30px Helvetica";
    ctx.textAlign = "center";

    if (data["8"]) {
      ctx.fillText(data.n8, cx, 120 * f);
    }
    if (data["1"]) {
      ctx.fillText(data.n1, cx, 355 * f);
    }
    if (data["2"]) {
      ctx.fillText(data["n2"], cx, 450 * f);
    }
    if (data["5"]) {
      ctx.fillText(data["n5"], cx - 130 * f, 290 * f);
    }
    if (data["6"]) {
      ctx.fillText(data["n6"], cx - 90 * f, 225 * f);
    }
    if (data["3"]) {
      ctx.fillText(data["n3"], cx + 130 * f, 290 * f);
    }
    if (data["4"]) {
      ctx.fillText(data["n4"], cx + 90 * f, 225 * f);
    }
    if (data["7"]) {
      ctx.fillText(data["n7"], cx - 160 * f, 160 * f);
    }
    if (data["9"]) {
      ctx.fillText(data["n9"], cx + 160 * f, 160 * f);
    }
    // Current Batter
    ctx.fillStyle = "#0085D1";
    if (live_board.hitter_name && live_board.pitcher_name) {
      if (live_board.hitter_lefty) {
        ctx.fillText(
          "#" + live_board.hitter_number + " " + live_board.hitter_name,
          cx + 60 * f,
          420 * f
        );
      } else {
        ctx.fillText(
          "#" + live_board.hitter_number + " " + live_board.hitter_name,
          cx - 60 * f,
          420 * f
        );
      }
      ctx.fill();
    }
    if (live_board.on_first) {
      ctx.fillText(
        "#" +
          live_board.on_first_atbat.uni +
          " " +
          live_board.on_first_atbat.name,
        cx + 120 * f,
        335 * f
      );
    }
    if (live_board.on_second) {
      ctx.fillText(
        "#" +
          live_board.on_second_atbat.uni +
          " " +
          live_board.on_second_atbat.name,
        cx,
        255 * f
      );
    }
    if (live_board.on_third) {
      ctx.fillText(
        "#" +
          live_board.on_third_atbat.uni +
          " " +
          live_board.on_third_atbat.name,
        cx - 120 * f,
        335 * f
      );
    }
    ctx.stroke();
    ctx.restore();
  };

  drawWithBackgroundImage(
    ctx,
    width,
    height,
    backgroundImage,
    f,
    data,
    live_board
  );
}

export function drawZone(ctx, width, height, data, backgroundImage = null) {
  ctx.clearRect(0, 0, width, height);

  var f = function (ctx, width, height, data) {
    var newX0 = width / 2 - 125 / 2;
    var newY0 = height - 150;
    if (newY0) {
      newY0 = newY0 - 10;
    }
    ctx.strokeStyle = "white";
    ctx.strokeRect(newX0, newY0, 125, 150);
    ctx.strokeStyle = "red";
    ctx.setLineDash([4, 2]);
    ctx.strokeRect(newX0 + 37, newY0 + 44, 51, 62);
    ctx.beginPath();
    ctx.fill();
    ctx.closePath();
    var i;
    for (i = 0; i < data.length; i++) {
      var pitch = data[i];
      placePitchDot(
        ctx,
        newX0 + pitch.x,
        newY0 + pitch.y,
        pitch.numb,
        pitch.result
      );
    }
  };

  if (backgroundImage) {
    drawWithBackgroundImage(ctx, width, height, backgroundImage, f, data);
  } else {
    f(ctx, 125, 150, data);
  }
}

export function drawLastPlayField(ctx, width, height, data) {
  // Draw field

  ctx.clearRect(0, 0, width, height);
  if (data.xloc || data.yloc) {
    var cx = 150;
    var cy = 130;
    var x = data.xloc;
    var y = data.yloc;

    ctx.beginPath();
    // Draw outer field
    ctx.strokeStyle = "black";
    ctx.moveTo(cx, 230);
    ctx.arc(cx, 230, 210, toRadians(225), toRadians(315), false); // outer (filled)
    ctx.lineTo(cx, 230);
    ctx.moveTo(cx, cy);
    ctx.stroke();
    ctx.closePath();
    // Color infield
    ctx.fillStyle = "lightgreen";
    ctx.beginPath();
    ctx.moveTo(cx, 230);
    ctx.arc(cx, 230, 210, toRadians(225), toRadians(315));
    ctx.lineTo(cx, 230);
    ctx.closePath();
    ctx.fill();
    ctx.fillStyle = "#FFE9C9";
    ctx.beginPath();
    ctx.arc(cx, 195, 7, 0, Math.PI * 2);
    ctx.closePath();
    ctx.fill();
    ctx.fillStyle = "#FFE9C9";
    ctx.beginPath();
    ctx.arc(cx, 230, 100, toRadians(225), toRadians(315), false); // outer (filled)
    ctx.arc(cx, 230, 63, toRadians(315), toRadians(225), true); // outer (unfills it)
    ctx.fill();
    ctx.closePath();
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.fillStyle = "blue";
    ctx.strokeStyle = "blue";
    ctx.arc(x, y, 5, 0, Math.PI * 2);
    ctx.stroke();
    ctx.fill();
    if (data.flight) {
      ctx.moveTo(cx, 230);
      if (data.flight === 2) {
        ctx.quadraticCurveTo(cx, y * 2, x, y);
      } else if (data.flight === 3) {
        ctx.quadraticCurveTo(cx, y * 0.1, x, y);
      } else {
        ctx.quadraticCurveTo(x, y, x, y);
      }
      ctx.stroke();
    }
    ctx.closePath();
  }
}

export function drawHeatmap(container, data) {
  container.classList.add("radar");
  data.heatmap = h337.create({
    container: container,
    radius: 10,
    blur: 0.8,
    maxOpacity: 0.6,
  });
  var cdata = {
    max: data.max,
    data: data.data,
  };
  data.heatmap.setData(cdata);
}

export function drawSprayChart(ctx, width, height, data) {
  if (!ctx) return;
  var rainbow = new Rainbow();
  var cx = width / 2;
  var cy = height;
  ctx.clearRect(0, 0, width, height);
  ctx.strokeStyle = "#777777";
  //next
  ctx.beginPath();
  ctx.fillStyle = getFieldFill(rainbow, data.regions.s1_127);
  ctx.moveTo(cx, cy);
  ctx.arc(cx, cy, cy, toRadians(225), toRadians(243));
  ctx.moveTo(cx, cy);
  ctx.fill();
  ctx.stroke();
  ctx.closePath();
  //next
  ctx.beginPath();
  ctx.fillStyle = getFieldFill(rainbow, data.regions.s2_127);
  ctx.moveTo(cx, cy);
  ctx.arc(cx, cy, cy, toRadians(243), toRadians(261));
  ctx.moveTo(cx, cy);
  ctx.fill();
  ctx.stroke();
  ctx.closePath();
  //next
  ctx.beginPath();
  ctx.fillStyle = getFieldFill(rainbow, data.regions.s3_127);
  ctx.moveTo(cx, cy);
  ctx.arc(cx, cy, cy, toRadians(261), toRadians(279));
  ctx.moveTo(cx, cy);
  ctx.fill();
  ctx.stroke();
  ctx.closePath();
  //next
  ctx.beginPath();
  ctx.fillStyle = getFieldFill(rainbow, data.regions.s4_127);
  ctx.moveTo(cx, cy);
  ctx.arc(cx, cy, cy, toRadians(279), toRadians(297));
  ctx.moveTo(cx, cy);
  ctx.fill();
  ctx.stroke();
  ctx.closePath();
  //next
  ctx.beginPath();
  ctx.fillStyle = getFieldFill(rainbow, data.regions.s5_127);
  ctx.moveTo(cx, cy);
  ctx.arc(cx, cy, cy, toRadians(297), toRadians(315));
  ctx.lineTo(cx, cy);
  ctx.fill();
  ctx.stroke();
  ctx.closePath();
  //next layer
  ctx.beginPath();
  ctx.fillStyle = getFieldFill(rainbow, data.regions.s1_0);
  ctx.moveTo(cx, cy);
  ctx.arc(cx, cy, cy / 2, toRadians(225), toRadians(243));
  ctx.moveTo(cx, cy);
  ctx.fill();
  ctx.stroke();
  ctx.closePath();
  //next
  ctx.beginPath();
  ctx.fillStyle = getFieldFill(rainbow, data.regions.s2_0);
  ctx.moveTo(cx, cy);
  ctx.arc(cx, cy, cy / 2, toRadians(243), toRadians(261));
  ctx.moveTo(cx, cy);
  ctx.fill();
  ctx.stroke();
  ctx.closePath();
  //next
  ctx.beginPath();
  ctx.fillStyle = getFieldFill(rainbow, data.regions.s3_0);
  ctx.moveTo(cx, cy);
  ctx.arc(cx, cy, cy / 2, toRadians(261), toRadians(279));
  ctx.moveTo(cx, cy);
  ctx.fill();
  ctx.stroke();
  ctx.closePath();
  //next
  ctx.beginPath();
  ctx.fillStyle = getFieldFill(rainbow, data.regions.s4_0);
  ctx.moveTo(cx, cy);
  ctx.arc(cx, cy, cy / 2, toRadians(279), toRadians(297));
  ctx.moveTo(cx, cy);
  ctx.fill();
  ctx.stroke();
  ctx.closePath();
  //next
  ctx.beginPath();
  ctx.fillStyle = getFieldFill(rainbow, data.regions.s5_0);
  ctx.moveTo(cx, cy);
  ctx.arc(cx, cy, cy / 2, toRadians(297), toRadians(315));
  ctx.fill();
  ctx.stroke();
  ctx.closePath();
  ctx.beginPath();
  ctx.font = "bold 14px Helvetica";
  ctx.textAlign = "center";
  ctx.fillStyle = "white";
  ctx.strokeStyle = "white";
  ctx.textAlign = "center";
  if (data.regions.s1_127) {
    ctx.fillText(
      translateDisplay(data.display_type, data.regions.s1_127) + "%",
      cx * 0.3,
      cy * 0.33
    );
  }
  if (data.regions.s2_127) {
    ctx.fillText(
      translateDisplay(data.display_type, data.regions.s2_127) + "%",
      cx * 0.65,
      cy * 0.23
    );
  }
  if (data.regions.s3_127) {
    ctx.fillText(
      translateDisplay(data.display_type, data.regions.s3_127) + "%",
      cx,
      cy * 0.18
    );
  }
  if (data.regions.s4_127) {
    ctx.fillText(
      translateDisplay(data.display_type, data.regions.s4_127) + "%",
      cx + cx * 0.35,
      cy * 0.23
    );
  }
  if (data.regions.s5_127) {
    ctx.fillText(
      translateDisplay(data.display_type, data.regions.s5_127) + "%",
      cx + cx * 0.7,
      cy * 0.33
    );
  }
  if (data.regions.s3_0) {
    ctx.fillText(
      translateDisplay(data.display_type, data.regions.s3_0) + "%",
      cx,
      cy * 0.6
    );
  }
  ctx.fillStyle = "black";
  ctx.strokeStyle = "grey";
  if (data.regions.s1_0) {
    ctx.moveTo(cx * 0.7, cy * 0.65);
    ctx.lineTo(cx * 0.3, cy * 0.74);
    ctx.fillText(
      translateDisplay(data.display_type, data.regions.s1_0) + "%",
      cx * 0.2,
      cy * 0.78
    );
  }
  if (data.regions.s2_0) {
    ctx.moveTo(cx * 0.84, cy * 0.65);
    ctx.lineTo(cx * 0.6, cy * 0.8);
    ctx.fillText(
      translateDisplay(data.display_type, data.regions.s2_0) + "%",
      cx * 0.55,
      cy * 0.85
    );
  }
  if (data.regions.s4_0) {
    ctx.moveTo(cx + cx * 0.16, cy * 0.65);
    ctx.lineTo(cx + cx * 0.4, cy * 0.8);
    ctx.fillText(
      translateDisplay(data.display_type, data.regions.s4_0) + "%",
      cx + cx * 0.45,
      cy * 0.85
    );
  }
  if (data.regions.s5_0) {
    ctx.moveTo(cx + cx * 0.3, cy * 0.65);
    ctx.lineTo(cx + cx * 0.7, cy * 0.74);
    ctx.fillText(
      translateDisplay(data.display_type, data.regions.s5_0) + "%",
      cx + cx * 0.8,
      cy * 0.78
    );
  }
  ctx.stroke();
  ctx.closePath();
}

export function drawRegions(box, data) {
  var i;
  var rainbow = new Rainbow();
  rainbow.setSpectrum("#0000ff", "#ff0000");
  if (data.display_type === "batting_avg") {
    if (data.rmax > 0.5) {
      rainbow.setNumberRange(0.0, data.rmax);
    } else {
      rainbow.setNumberRange(0.0, 0.5);
    }
  } else if (data.rmax) {
    rainbow.setNumberRange(0.0, data.rmax);
  }
  for (i = 0; i < data.regions.length; i++) {
    var el = box.getElementsByClassName("t" + data.regions[i].region + "t")[0];
    console.log(data.regions[i]);
    if (data.regions[i].value !== "-") {
      el.innerHTML = translateDisplay(
        data.display_type,
        data.regions[i].value,
        false
      );
      el.style.backgroundColor =
        "#" +
        rainbow.colourAt(
          translateDisplay(data.display_type, data.regions[i].value, true)
        );
    } else {
      el.innerHTML = "-";
      el.style.backgroundColor = "#0000ff";
    }
  }
}
