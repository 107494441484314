import React, { useRef, useEffect } from "react";

import { drawWithBackgroundImage } from "../../utils/draw.js";


const GameCanvas = ({ drawFunc, data, backgroundImage=null, ...props }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    if (backgroundImage) {
      drawWithBackgroundImage(context, canvas.width, canvas.height, backgroundImage, drawFunc, data);
    } else {
      drawFunc(context, canvas.width, canvas.height, data);
    }
  }, [drawFunc, backgroundImage, data]);

  return <canvas ref={canvasRef} {...props} />;
};

export default GameCanvas;