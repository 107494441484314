import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Table, Tabs, Tab, Spinner } from "react-bootstrap";

import {
  drawSequenceCanvas,
  drawSituationPlayCanvas,
  drawSprayChart,
} from "../../utils/draw";

import {
  setVideoIsPlaying,
  setCurrentPlayingVideo,
  setCurrentPlaylist
} from "../../redux/game/game.actions";

import {
    selectPlayerPagePlaylist
} from '../../redux/game/game.selectors';

import "./player-profile.styles.scss";

import HeaderBackground from "../../assets/headerbackground.png";
import FieldBackground from "../../assets/feedfield.png";
import GameCanvas from "../game-canvas/game-canvas.component";
import HeatmapCanvas from "../game-heatmap-canvas/game-heatmap-canvas.component";
import PlayerABTables from "../player-ab-tables/player-ab-tables.component";
import VideoPreviewImage from "../video-preview/video-preview.component";
import GameRegionTable from '../game-region-table/game-region-table.component';

const PlayerProfileHeader = ({ profile, gameInfo }) => {
  return (
    <div className="player-profile-header">
      <div
        className="profile-content"
        style={{ backgroundImage: `url(${HeaderBackground})` }}
      >
        <h2>{profile.full_name}</h2>
        <h3>
          {gameInfo.final_score} {gameInfo.display_versus}
        </h3>
        <h3>
          {gameInfo.game_date}{" "}
          {gameInfo.location_name ? gameInfo.location_name : null}
        </h3>
      </div>
    </div>
  );
};

const TableSGM = ({ statLine }) => (
  <Table className="game-table" bordered hover striped>
    <thead>
      <tr>
        <th>ABs</th>
        <th>H</th>
        <th>2B</th>
        <th>3B</th>
        <th>HR</th>
        <th>BB</th>
        <th>K</th>
        <th>RBI</th>
        <th>SB</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{statLine.abs}</td>
        <td>{statLine.hits}</td>
        <td>{statLine.doubles}</td>
        <td>{statLine.triples}</td>
        <td>{statLine.hrs}</td>
        <td>{statLine.bbs}</td>
        <td>{statLine.ks}</td>
        <td>{statLine.rbis}</td>
        <td>{statLine.sbs}</td>
      </tr>
    </tbody>
  </Table>
);

const ABTabs = ({ game }) => (
  <Tabs defaultActiveKey="ab-0" className="nav-justified">
    {game.data_table.map((data, idx) => (
      <Tab key={`key-ab-${data.key}`} eventKey={`ab-${idx}`} title={data.name}>
        <PlayerABTables data={data} idx={idx} />
        <div className="boxes-canvases-container">
          <CanvasesBoxes key={`canvases-boxes-${idx}`} boxes={game.slider[idx].boxes} />
        </div>
      </Tab>
    ))}
  </Tabs>
);

const CanvasBox = ({ type, data }) => {
  const dispatch = useDispatch();
  const [pl, setPL] = useState(null);
  const playerPlaylist = useSelector(selectPlayerPagePlaylist);
  useEffect(() => {
    setPL(playerPlaylist);
  }, [playerPlaylist])
  switch (type) {
    case "video":
      return (
        <div className="video-canvas-container">
          {data.length < 1 ? null : (
            <VideoPreviewImage
              imageUrl={data[0].thumbnail_url}
              onClick={() => {
                dispatch(setCurrentPlaylist(pl));
                dispatch(
                  setCurrentPlayingVideo({
                    url: data[0].video_url,
                    title: data[0].title,
                    details: data[0].description,
                  })
                );
                dispatch(setVideoIsPlaying(true));
              }}
            />
          )}
        </div>
      );
    case "seq":
      return (
        <GameCanvas
          drawFunc={drawSequenceCanvas}
          data={data.data}
          width="150"
          height="180"
        />
      );
    case "chart":
      return (
        <GameCanvas
          backgroundImage={FieldBackground}
          drawFunc={drawSituationPlayCanvas}
          data={data.data}
          width="220"
          height="150"
        />
      );
    case "heat":
      return <HeatmapCanvas data={data} />;
    default:
      return null;
  }
};

const CanvasesBoxes = ({ boxes }) => {
  return boxes.map((box, idx) => {
    return box.data || box.vids ? (
      <div className="box-canvas-wrapper">
        <CanvasBox
          key={`key-box-canvas-${idx}`}
          type={box.type}
          data={box.data || box.vids}
        />
      </div>
    ) : null;
  });
};

const MetricsTabs = ({ tabHeaders, tabValues }) => (
  <Tabs defaultActiveKey="metrics-0-pitchcount" className="nav-justified">
    {tabHeaders.map((header, idx) => (
      <Tab
        key={`metrics-${idx}`}
        eventKey={`metrics-${idx}-${header.key}`}
        title={header.name}
      >
        <div className="metric-charts-container">
          <div className="spray-chart-canvas-container">
            <GameCanvas
              drawFunc={drawSprayChart}
              data={tabValues[header.key].spray}
              width="220"
              height="150"
            />
          </div>
          <HeatmapCanvas data={tabValues[header.key].heat} />
          <GameRegionTable data={tabValues[header.key].quad} />
        </div>
        <h3>{tabValues[header.key].quad.filter_string}</h3>
      </Tab>
    ))}
  </Tabs>
);

const PlayerProfile = ({ gameProfile }) => {
  return gameProfile ? (
    <div className="player-profile-container">
      <PlayerProfileHeader
        profile={gameProfile.player}
        gameInfo={gameProfile.game.game_details}
      />
      {gameProfile.game.chart_key.startsWith("sgm") ? (
        <TableSGM statLine={gameProfile.game.stat_line} />
      ) : null}
      {gameProfile.game.coptions && gameProfile.game.metrics ? (
        <MetricsTabs
          tabHeaders={gameProfile.game.coptions}
          tabValues={gameProfile.game.metrics}
        />
      ) : null}
      <ABTabs game={gameProfile.game} />
    </div>
  ) : <Spinner />;
};

export default PlayerProfile;
