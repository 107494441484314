import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import { Spinner } from "../../components/with-spinner/with-spinner.component";
import PlayerProfile from "../../components/player-profile/player-profile.component";

import {
  getPlayerPageStart,
  setPlayerPageLoadingStart,
} from "../../redux/game/game.actions";

import {
  selectPlayerPageLoadingFlag,
  selectPlayerPage
} from "../../redux/game/game.selectors";

import "./game-player.styles.scss";

const GamePlayerPage = ({ match }) => {
  const { playerId, gameId } = match.params;
  const gameProfile = useSelector(selectPlayerPage);
  const playerPageLoadingFlag = useSelector(selectPlayerPageLoadingFlag);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchGamePlayerInfo() {
    //   setIsLoading(true);
      dispatch(setPlayerPageLoadingStart());
      dispatch(getPlayerPageStart({ playerId, gameId }));
    }

    fetchGamePlayerInfo();
  }, [playerId, gameId]);

//   useEffect(() => {
//     console.log(gameProfile, playerPlaylist, playerPlaylistError);
//     if (gameProfile && (!!playerPlaylist || !!playerPlaylistError)) {
//       setIsLoading(false);
//     }
//   }, [gameProfile, playerPlaylist, playerPlaylistError]);

  return playerPageLoadingFlag ? (
    <Spinner />
  ) : (
    <div className="game-player-container">
      <Link to={`/games/${match.params.appId}`}>Back to game</Link>
      <PlayerProfile gameProfile={gameProfile} />
    </div>
  );
};

export default withRouter(GamePlayerPage);
