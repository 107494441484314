import React, { useRef, useEffect } from "react";

import { drawField } from "../../utils/draw.js";

import FieldImage from '../../assets/field.png';

import "./game-field-canvas.styles.scss";

const GameFieldCanvas = ({ live_board, field, ...props }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    drawField(context, canvas.width, canvas.height, field, live_board, FieldImage);
  }, [live_board, field]);

  return <canvas ref={canvasRef} {...props} />;
};

export default GameFieldCanvas;
