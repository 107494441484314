import React from "react";

import { SpinnerOverlay, SpinnerContainer } from "./with-spinner.styles";

export const Spinner = () => (
    <SpinnerOverlay>
      <SpinnerContainer />
    </SpinnerOverlay>
)

const WithSpinner = (WrappedComponent) => ({ isLoading, ...otherProps }) => {
  const Spinner = isLoading ? (
    <SpinnerOverlay>
      <SpinnerContainer />
    </SpinnerOverlay>
  ) : (
    <WrappedComponent {...otherProps} />
  );
  return Spinner;
};

export default WithSpinner;
