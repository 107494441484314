import axios from "axios";
import qs from "querystring";

export async function refreshTokens(tokens) {
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  const response = await axios.post(
    "https://pitchaware.com/o/token/",
    qs.stringify({
      refresh_token: tokens.refresh_token,
      grant_type: "refresh_token",
      client_id: "An931tYhtpvu6WRWVXhcxAKy8OfvLhP8jWnWRVOm",
      client_secret:
        "RD0mFUNOB8wrGMFSrUkE2CrpGgCkjcqmhMpfIGIvQgDNnxo7c1cQkYc4tEJZDfSEfmqcrMIyyukLX6nYk9teOSaZI388nuows2x7k3CMRnwKvJJ1DkUpDG85oAVhpFtb",
    }),
    config
  );

  return response.data;
}

export function* getDataWithTokens(tokens, url) {
  const response = yield fetch(url, {
    headers: new Headers({
      Authorization: `${tokens.token_type} ${tokens.access_token}`,
    }),
  });
  const result = yield response.json();
  return {'status': {'status': response.status, 'tokens': tokens, 'url': url}, result};
}
