import { takeLatest, put, all, call, select } from "redux-saga/effects";

import { getDataWithTokens } from "../../utils/utils";

import {
  getAppGameDataSuccess,
  getAppGameDataFailure,
  fetchCurrentGameDataSuccess,
  fetchCurrentGameDataFailure,
  getPlayerPageSuccess,
  getPlayerPageFailure,
  fetchPlayerPagePlaylistStart,
  fetchPlayerPagePlaylistSuccess,
  fetchPlayerPagePlaylistFailure,
  fetchHighlightsPlaylistStart,
  fetchHighlightsPlaylistSuccess,
  fetchHighlightsPlaylistFailure,
  fetchCurrentPBPGameSuccess,
  fetchCurrentPBPGameFailure,
  fetchPBPPlaylistStart,
  fetchPBPPlaylistSuccess,
  fetchPBPPlaylistFailure,
  fetchBoxSuccess,
  fetchBoxFailure,
  setCurrentPlaylist,
  setPlayerPageLoadingSuccess,
  setCurrentPlayingVideo,
} from "./game.actions";
import { selectTokens } from "../user/user.selectors";

import GameActionTypes from "./game.types";

const PA_API_PREFIX = "https://www.pitchaware.com/api";
const CURRENT_GAME_DATA_PREFIX =
  "https://pitchaware-web-prod.s3.amazonaws.com/media/livestats/mg";

export function* getAppGameData({ payload: appId }) {
  try {
    const tokens = yield select(selectTokens);
    const { status, result } = yield getDataWithTokens(
      tokens,
      `${PA_API_PREFIX}/game/app/${appId}`
    );
    if (status.status === 200) {
      yield put(getAppGameDataSuccess(result));
    } else {
      yield put(getAppGameDataFailure(status));
    }
  } catch (error) {
    yield put(getAppGameDataFailure(error));
  }
}

export function* fetchCurrentGameData({ payload: currentGame }) {
  try {
    const curentGameResponse = yield fetch(
      `${CURRENT_GAME_DATA_PREFIX}${currentGame}_game.json`
    );
    if (curentGameResponse.status === 200) {
      const currentGameResult = yield curentGameResponse.json();
      yield put(fetchCurrentGameDataSuccess(currentGameResult));
    } else {
      yield put(fetchCurrentGameDataFailure(curentGameResponse.status));
    }
  } catch (error) {
    yield put(fetchCurrentGameDataFailure(error));
  }
}

export function* getPlayerPage({ payload: { playerId, gameId } }) {
  try {
    const tokens = yield select(selectTokens);
    const { status, result } = yield getDataWithTokens(
      tokens,
      `${PA_API_PREFIX}/player/${playerId}?gid=${encodeURIComponent(
        gameId
      )}&p=games`
    );
    if (status.status === 200) {
      yield put(getPlayerPageSuccess(result));
    } else {
      yield put(getPlayerPageFailure(status));
    }
  } catch (error) {
    yield put(getPlayerPageFailure(error));
  }
}

export function* fetchPlayerPagePlaylist({ payload: playlistURL }) {
  try {
    const playlistResponse = yield fetch(playlistURL);
    if (playlistResponse.status === 200) {
      const playlistResult = yield playlistResponse.json();
      yield put(fetchPlayerPagePlaylistSuccess(playlistResult));
    } else {
      yield put(fetchPlayerPagePlaylistFailure(playlistResponse));
    }
  } catch (error) {
    yield put(fetchPlayerPagePlaylistFailure(error));
  }
}

export function* fetchHighlightsPlaylist({ payload: playlistURL }) {
  try {
    const playlistResponse = yield fetch(playlistURL);
    if (playlistResponse.status === 200) {
      const playlistResult = yield playlistResponse.json();
      yield put(fetchHighlightsPlaylistSuccess(playlistResult));
    } else {
      yield put(fetchHighlightsPlaylistFailure(playlistResponse));
    }
  } catch (error) {
    yield put(fetchHighlightsPlaylistFailure(error));
  }
}

export function* fetchCurrentPBPData({ payload: currentGame }) {
  try {
    const pbpResponse = yield fetch(
      `${CURRENT_GAME_DATA_PREFIX}${currentGame}_pbp.json`
    );
    if (pbpResponse.status === 200) {
      const pbpResult = yield pbpResponse.json();
      yield put(fetchCurrentPBPGameSuccess(pbpResult));
    } else {
      yield put(fetchCurrentPBPGameFailure(pbpResponse.status));
    }
  } catch (error) {
    yield put(fetchCurrentPBPGameFailure(error));
  }
}

export function* fetchBoxData({ payload: currentGame }) {
  try {
    const boxResponse = yield fetch(
      `${CURRENT_GAME_DATA_PREFIX}${currentGame}_box.json`
    );
    if (boxResponse.status === 200) {
      const boxResult = yield boxResponse.json();
      yield put(fetchBoxSuccess(boxResult));
    } else {
      yield put(fetchBoxFailure(boxResponse.status));
    }
  } catch (error) {
    yield put(fetchBoxFailure(error));
  }
}

export function* fetchPBPPlaylist({ payload: playlistURL }) {
  try {
    const playlistResponse = yield fetch(playlistURL);
    if (playlistResponse.status === 200) {
      const playlistResult = yield playlistResponse.json();
      yield put(fetchPBPPlaylistSuccess(playlistResult));
    } else {
      yield put(fetchPBPPlaylistFailure(playlistResponse));
    }
  } catch (error) {
    yield put(fetchPBPPlaylistFailure(error));
  }
}

export function* onAppGameDataStart() {
  yield takeLatest(GameActionTypes.GET_APP_GAME_DATA_START, getAppGameData);
}

export function* onCurrentGameDataStart() {
  yield takeLatest(
    GameActionTypes.FETCH_CURRENT_GAME_DATA_START,
    fetchCurrentGameData
  );
}

export function* onCurrentGameDataSuccess() {
  yield takeLatest(GameActionTypes.FETCH_CURRENT_GAME_DATA_SUCCESS, function* (
    data
  ) {
    yield put(
      fetchHighlightsPlaylistStart(
        data.payload.live_board.highlight_playlist_key
      )
    );
    yield put(
      fetchPBPPlaylistStart(data.payload.live_board.video_playlist_key)
    );
  });
}

export function* onPlayerPageStart() {
  yield takeLatest(GameActionTypes.GET_PLAYER_PAGE_START, getPlayerPage);
}

export function* onPlayerPagePlaylistStart() {
  yield takeLatest(
    GameActionTypes.FETCH_PLAYER_PAGE_PLAYLIST_START,
    fetchPlayerPagePlaylist
  );
}

export function* onPlayerPagePlaylistSuccess() {
  yield takeLatest(
    GameActionTypes.FETCH_PLAYER_PAGE_PLAYLIST_SUCCESS,
    function* () {
      yield put(setPlayerPageLoadingSuccess());
    }
  );
}

export function* onPlayerPagePlaylistFailure() {
  yield takeLatest(
    GameActionTypes.FETCH_PLAYER_PAGE_PLAYLIST_FAILURE,
    function* () {
      yield put(setPlayerPageLoadingSuccess());
    }
  );
}

export function* onHighlightsPlaylistStart() {
  yield takeLatest(
    GameActionTypes.FETCH_HIGHLIGHTS_PLAYLIST_START,
    fetchHighlightsPlaylist
  );
}

export function* onHighlightsPlaylistSuccess() {
  yield takeLatest(
    GameActionTypes.FETCH_HIGHLIGHTS_PLAYLIST_SUCCESS,
    function* (data) {
      yield put(setCurrentPlaylist(data.payload));
      yield put(setCurrentPlayingVideo(data.payload.videos[0]));
    }
  );
}

export function* onPBPPlaylistStart() {
  yield takeLatest(GameActionTypes.FETCH_PBP_PLAYLIST_START, fetchPBPPlaylist);
}

export function* onBoxStart() {
  yield takeLatest(GameActionTypes.FETCH_BOX_START, fetchBoxData);
}

export function* onCurrentPBPGameStart() {
  yield takeLatest(
    GameActionTypes.FETCH_CURRENT_PBP_GAME_START,
    fetchCurrentPBPData
  );
}

export function* onPlayerPageSuccess() {
  yield takeLatest(GameActionTypes.GET_PLAYER_PAGE_SUCCESS, function* (data) {
    yield put(
      fetchPlayerPagePlaylistStart(data.payload.game.video_playlist_key)
    );
  });
}

export function* gameSagas() {
  yield all([
    call(onAppGameDataStart),
    call(onCurrentGameDataStart),
    call(onPlayerPageStart),
    call(onHighlightsPlaylistStart),
    call(onHighlightsPlaylistSuccess),
    call(onCurrentGameDataSuccess),
    call(onPlayerPagePlaylistStart),
    call(onPlayerPageSuccess),
    call(onCurrentPBPGameStart),
    call(onPBPPlaylistStart),
    call(onPlayerPagePlaylistFailure),
    call(onPlayerPagePlaylistSuccess),
    call(onBoxStart),
  ]);
}
