import { combineReducers } from "redux";

import { persistReducer } from "redux-persist";

import storage from "redux-persist/lib/storage";

import gameReducer from "./game/game.reducer";
import userReducer from './user/user.reducer';


const persistConfig = {
  key: "root",
  storage,
  blacklist: ['user', 'game'],
};

const userPersistConfig = {
  key: 'user',
  storage,
  whitelist: ['tokens']
}

const rootReducer = combineReducers({
  game: gameReducer,
  user: persistReducer(userPersistConfig, userReducer)
});

export default persistReducer(persistConfig, rootReducer);
