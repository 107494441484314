import { createStore, applyMiddleware } from "redux";
import { persistStore } from "redux-persist";
import createSagaMiddleware from 'redux-saga';

import logger from "redux-logger";

import rootReducer from "./root-reducer";
import rootSaga from './root-saga';
import { onMonitor } from "./monitor.sagas";

const sagaMiddleware = createSagaMiddleware()

const middlewares = [sagaMiddleware];

if (process.env.NODE_ENV === 'development') {
    middlewares.push(logger);
}


export const store = createStore(rootReducer, applyMiddleware(...middlewares));

export const persistor = persistStore(store);


sagaMiddleware.run(onMonitor);
sagaMiddleware.run(rootSaga);