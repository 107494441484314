import React, { useRef, useEffect } from "react";

import "./game-region-table.styles.scss";
import { drawRegions } from "../../utils/draw";

const GameRegionTable = ({ data }) => {
  const containerRef = useRef(null);
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      drawRegions(container, data);
    }
  }, [containerRef, data]);
  return (
    <div className="region" ref={containerRef}>
      <table>
        <tbody>
          <tr>
            <td className="t51t"></td>
            <td className="t52t"></td>
            <td className="t53t"></td>
            <td className="t54t"></td>
            <td className="t55t"></td>
          </tr>
          <tr>
            <td className="t41t"></td>
            <td className="t42t"></td>
            <td className="t43t"></td>
            <td className="t44t"></td>
            <td className="t45t"></td>
          </tr>
          <tr>
            <td className="t31t"></td>
            <td className="t32t"></td>
            <td className="t33t"></td>
            <td className="t34t"></td>
            <td className="t35t"></td>
          </tr>
          <tr>
            <td className="t21t"></td>
            <td className="t22t"></td>
            <td className="t23t"></td>
            <td className="t24t"></td>
            <td className="t25t"></td>
          </tr>
          <tr>
            <td className="t11t"></td>
            <td className="t12t"></td>
            <td className="t13t"></td>
            <td className="t14t"></td>
            <td className="t15t"></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default GameRegionTable;
