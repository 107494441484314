import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import Scoreboard from "../scoreboard/scoreboard.component";

import { Spinner } from "../../components/with-spinner/with-spinner.component";

import "./livestats.styles.scss";

const Livestats = () => {
  const data = useSelector((state) => state.game.currentGameData);
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    setIsLoading(true);
    setIsLoading(false);
  }, [data])
  return isLoading ? (
    <Spinner />
  ) : (
    <div className="live-stats">
      <div className="info">
        <Scoreboard
          league={data.league}
          live_board={data.live_board}
          innings={data.innings}
          sboard={data.sboard}
          finals={data.finals}
        />
      </div>
    </div>
  );
};

export default Livestats;
