import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Spinner } from "../with-spinner/with-spinner.component";
import VideoPreviewImage from "../video-preview/video-preview.component";

import {
  setCurrentPlayingVideo,
  setCurrentPlaylist,
  setVideoIsPlaying,
} from "../../redux/game/game.actions";

import {
  selectCurrentPBPGameData,
  selectPBPPlaylist,
} from "../../redux/game/game.selectors";

import { Table, Accordion, Card } from "react-bootstrap";

import "./pbp.styles.scss";

const PitchIconPBP = ({ number, styles }) => (
  <div
    className="pitch-icon"
    style={{ backgroundColor: styles[0], border: `1px ${styles[1]} solid` }}
  >
    {number}
  </div>
);


const PlayDetailsCard = ({ idx, number, details, playlistPBP, keyPrefix }) => {
  const dispatch = useDispatch()

  return (
    <Card>
      {details.pitches ? (
        <>
          <Accordion.Toggle
            as={Card.Header}
            eventKey={`${keyPrefix}-${number}-${idx}`}
            key={`${keyPrefix}-${number}-${idx}`}
            className={`${details.is_scoring_play ? "scoring-header" : ""}`}
          >
            {details.text}{" "}
            {details.videos[0] ? (
              <VideoPreviewImage
                key={`pbp-video-preview-${keyPrefix}-${idx}`}
                imageUrl={details.videos[0].video_info.thumbnail}
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(setCurrentPlayingVideo({
                    url: details.videos[0].video_info.url,
                    title: details.videos[0].title,
                    description: details.videos[0].description
                  }));
                  dispatch(setVideoIsPlaying(true));
                  dispatch(setCurrentPlaylist(playlistPBP));
                }}
              />
            ) : null}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={`${keyPrefix}-${number}-${idx}`}>
            <Card.Body>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>PITCH</th>
                    <th>TYPE</th>
                    <th>MPH</th>
                  </tr>
                </thead>
                <tbody>
                  {details.pitches.map((pitch, idx) => (
                    <tr key={`pitch-details-${pitch.numb}-${idx}`}>
                      <td>
                        <PitchIconPBP
                          number={pitch.numb}
                          styles={pitch.color}
                        />
                        {pitch.pitch_type}
                      </td>
                      <td>{pitch.result_str}</td>
                      <td>{pitch.velo}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Accordion.Collapse>{" "}
        </>
      ) : (
        <Card.Body>{details.text}</Card.Body>
      )}
    </Card>
  );
};

const PBP = () => {
  const [isLoading, setIsLoading] = useState(true)
  const currentGameData = useSelector(selectCurrentPBPGameData);
  const currentGamePBP = useSelector((state) => state.game.currentGamePBP);
  const playlistPBP = useSelector(selectPBPPlaylist);

  useEffect(() => {
    if (!!currentGameData) {
      setIsLoading(false)
    }
  }, [currentGameData, playlistPBP])

  return isLoading ? (
    <Spinner />
  ) : (
    <div className="pbp-container">
      <Accordion>
        {currentGamePBP.all.map((all, ind) => {
          return (
            <div key={`current-game-${ind}`}>
              <Card>
                <Card.Body>
                  <h3>TOP {all.number}</h3>
                </Card.Body>
              </Card>
              {all.away_detail.map((away, idx) => (
                <PlayDetailsCard
                  key={`away-details-${idx}`}
                  idx={idx}
                  number={all.number}
                  details={away}
                  playlistPBP={playlistPBP}
                  keyPrefix="away"
                />
              ))}
              <Card>
                <Card.Body>
                  <h3>BOTTOM {all.number}</h3>
                </Card.Body>
              </Card>
              {all.home_detail.map((home, idx) => (
                <PlayDetailsCard
                  key={`home-details-${idx}`}
                  idx={idx}
                  number={all.number}
                  details={home}
                  playlistPBP={playlistPBP}
                  keyPrefix="home"
                />
              ))}
            </div>
          );
        })}
      </Accordion>
    </div>
  );
};

export default PBP;
