import { createSelector } from "reselect";

const selectGame = (state) => state.game;

export const selectCurrentGame = createSelector(
  [selectGame],
  (game) => game.currentGame
);

export const selectBox = createSelector([selectGame], (game) => game.box);

export const selectCurrentGameData = createSelector(
  [selectGame],
  (game) => game.currentGameData
);

export const selectAppGameData = createSelector(
  [selectGame],
  (game) => game.appGameData
);

export const selectCurrentLivestreams = createSelector(
  [selectGame],
  (game) => game.currentLivestreams
);

export const selectCurrentPlaylistWithErrorInfo = createSelector(
  [selectGame],
  (game) => ({
    currentPlaylist: game.currentPlaylist,
    currentPlaylistError: game.currentPlaylistError,
  })
);

export const selectCurrentPlayingVideo = createSelector(
  [selectGame],
  (game) => game.currentPlayingVideo
);

export const selectNextPlayingVideo = createSelector(
  [selectGame],
  (game) => game.nextPlayingVideo
);

export const selectLiveBoard = createSelector(
  [selectGame],
  (game) => game.live_board
);

export const selectPBPPlaylist = createSelector(
  [selectGame],
  (game) => game.playlistPBP
);

export const selectCurrentPBPGameData = createSelector(
  [selectGame],
  (game) => game.currentGamePBP
);

export const selectPlayerPage = createSelector(
  [selectGame],
  (game) => game.playerPage
);

export const selectPlayerPagePlaylist = createSelector(
  [selectGame],
  (game) => game.playerPagePlaylist
);

export const selectPlayerPagePlaylistWithErrorInfo = createSelector(
  [selectGame],
  (game) => ({
    playerPagePlaylist: game.playerPagePlaylist,
    playerPagePlaylistError: game.playerPagePlaylistError,
  })
);

export const selectPlayerPageLoadingFlag = createSelector(
  [selectGame],
  (game) => game.playerPageIsLoading
);

export const selectBoxData = createSelector([selectGame], (game) => game.box);
