import React from "react";

import "./video-preview.styles.scss";

const VideoPreviewImage = ({ imageUrl, ...props }) => {
  return (
    <div className="video-preview" {...props}>
      <div
        className="image"
        style={{
          backgroundImage: `url(${imageUrl})`
        }}
      >
        <i className="ico-play" />
      </div>
    </div>
  );
};

export default VideoPreviewImage;
