import UserActionTypes from "./user.types";

export const setCurrentUser = (user) => ({
  type: UserActionTypes.SET_CURRENT_USER,
  payload: user,
});

export const loginStart = (emailAndPassword) => ({
  type: UserActionTypes.LOGIN_START,
  payload: emailAndPassword,
});

export const loginSuccess = (tokens) => ({
  type: UserActionTypes.LOGIN_SUCCESS,
  payload: tokens,
});

export const loginFailure = (error) => ({
  type: UserActionTypes.LOGIN_FAILURE,
  payload: error,
});

export const refreshTokensStart = (refreshToken) => ({
  type: UserActionTypes.REFRESH_TOKENS_START,
  payload: refreshToken
});

export const refreshTokensSuccess = (tokens) => ({
  type: UserActionTypes.REFRESH_TOKENS_SUCCESS,
  payload: tokens,
});

export const refreshTokensFailure = (error) => ({
  type: UserActionTypes.REFRESH_TOKENS_FAILURE,
  payload: error,
});
