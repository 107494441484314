import React from "react";

export const FlameIcon = () => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      preserveAspectRatio="none"
      viewBox="0 0 217 268"
      style={{width: '100%', height:'100%' }}
    >
      <path
        style={{fill:'#d5705a', stroke:'none'}}
        d="M83 265C78.0313 255.012 72.2199 247.402 70.439 236C66.0394 207.831 84.4886 176.857 107 161C107.214 186.898 129.719 191.324 140.482 211C151.451 231.052 139.425 248.106 129 265C141.473 266.112 155.535 256.77 165 249.626C198.014 224.708 214.809 173.565 192.536 137C176.915 111.354 146.712 105.253 128.465 83C117.102 69.1435 108.778 50.0795 108 32C95.1029 36.1168 83.0031 48.9407 73 57.7153C37.1325 89.1777 6.10049 135.208 13 185C17.9129 220.455 42.0997 264.129 83 265z"
      />
      <path
        style={{fill:'#ff0000', stroke:'none'}}
        d="M118 68L119 69L118 68M199 151L200 152L199 151M201 157L202 158L201 157M203 168L204 169L203 168M94 172L95 173L94 172M202 183L203 184L202 183M79 192L80 193L79 192M18 209L19 210L18 209M144 234L145 235L144 234M71 241L72 242L71 241M142 241L143 242L142 241M72 245L73 246L72 245M167 247L168 248L167 247M82 263L83 264L82 263z"
      />
    </svg>
  </>
);

export default FlameIcon;
