import { createSelector } from 'reselect';

const selectUser = state => state.user;

// export const selectTokens = state => state.user.tokens;

export const selectTokens = createSelector(
    [selectUser],
    user => user.tokens
)
