import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Tags from "@yaireo/tagify/dist/react.tagify";

import {
  setVideoTagsCloud,
  setVideoTagsActive,
} from "../../redux/game/game.actions";

import { selectCurrentPlaylistWithErrorInfo } from "../../redux/game/game.selectors";

import "./video-tags.styles.scss";

const VideoTags = () => {
  const dispatch = useDispatch();
  const tagifyRef = useRef();
  const { currentPlaylist } = useSelector(selectCurrentPlaylistWithErrorInfo);
  const currentVideoTags = useSelector((state) => state.game.videoTagsCloud);
  useEffect(() => {
    if (tagifyRef.current) {
      tagifyRef.current.removeAllTags();
      dispatch(setVideoTagsActive([]));
    }

    if (currentPlaylist && currentPlaylist.tag_options) {
      dispatch(setVideoTagsCloud(currentPlaylist.tag_options));
    }
  }, [currentPlaylist, dispatch]);

  const handleChange = (e) => {
    var keys = [];
    try {
      keys = e.detail.tagify.value.map((item) => item.key);
      dispatch(setVideoTagsActive(keys));
    } catch {
      console.log("Bad JSON in the video tags cloud");
    }
  };

  const tagifySettings = {
    blacklist: [],
    maxTags: 10,
    addTagOnBlur: false,
    placeholder: "filter tags",
    dropdown: {
      enabled: 0,
      maxItems: Infinity,
      classname: "tags-look",
    },
    callbacks: {
      add: handleChange,
      remove: handleChange,
    },
  };

  return currentPlaylist ? (
    <div className="video-tags">
      <h3>Tags:</h3>
      <Tags
        tagifyRef={tagifyRef}
        mode="textarea"
        settings={tagifySettings}
        name="videoTags"
        value=""
        whitelist={currentVideoTags}
        showFilteredDropdown="a"
        // onChange={handleChange}
      />
    </div>
  ) : null;
};

export default VideoTags;
