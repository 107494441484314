import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import ReactPlayer from "react-player/lazy";

import { useFetch } from "../../hooks/utils";

import "./livestreams.styles.scss";

const Livestreams = () => {
    const data = useSelector(state => state.game.currentGameData);
    const [ streamActiveTab, setStreamActiveTab] = useState(0)
    return data !== null ? (
        <Tabs defaultActiveKey={data.livestreams[0].pk} id="section-b" className="nav-justified">
          {data.livestreams.map((stream, idx) => {
            const playerOptions = {
              width: '100%',
              height: '100%',
              controls: true,
              url: stream.viewer_url,
              playing: idx === streamActiveTab ? true : false,
              onStart: () => setStreamActiveTab(idx)
            }
            return (
            <Tab key={stream.pk} eventKey={stream.pk} title={stream.perspective_name} className="rounded-0">
              <div className='player-centered'>
                <ReactPlayer {...playerOptions} />
              </div>
            </Tab>
          )})}
        </Tabs>
  ) : null;
};

export default Livestreams;
