import UserActionTypes from "./user.types";

const INITIAL_STATE = {
  loggedIn: false,
  tokens: null,
  error: null
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.LOGIN_SUCCESS:
    case UserActionTypes.REFRESH_TOKENS_SUCCESS:
      return {
        ...state,
        tokens: action.payload,
        error: null,
        loggedIn: true
      }
    case UserActionTypes.LOGIN_FAILURE:
    case UserActionTypes.REFRESH_TOKENS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loggedIn: false
      }
    default:
      return state;
  }
};

export default userReducer;
