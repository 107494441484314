import React from "react";
import { Table, Row, Col } from "react-bootstrap";

import GameCanvas from "../game-canvas/game-canvas.component";
import ProfileIcon from "../profile-icon/profile-icon.component";
import FlameIcon from "../svg/flame.component";

import { showOutsCanvas } from '../../utils/draw.js';

import "./scoreboard.styles.scss";

const Scoreboard = ({ league, live_board, sboard, innings, finals }) => {
  return (
    <>
      <ul className="list-scores">
        <li key="list-scores-away-name">
          <div className="scores-data">
            <h2 className="team-name">{live_board.away_initials}</h2>
            <strong>{live_board.away_score || 0}</strong>
          </div>
        </li>
        <li key="list-scores-inning">
          {live_board.top_of_inning && !live_board.finished ? (
            <i className="arrow-orange-up" />
          ) : null}
          {live_board.finished ? (
            <small>F</small>
          ) : (
            <small>{live_board.inning}</small>
          )}
          {!live_board.top_of_inning && !live_board.finished ? (
            <i className="arrow-down-orange" />
          ) : null}
        </li>
        <li key="list-scores-home-name">
          <div className="scores-data">
            <strong>{live_board.home_score || 0}</strong>
            <h2 className="team-name">{live_board.home_initials}</h2>
          </div>
        </li>
      </ul>
      <div className="score-body">
        <h2>{league.event_name}</h2>
        <Table className="table-score">
          <thead>
            <tr>
              <th></th>
              {innings.map((inning) => (
                <th key={`th-${inning.number}`}>{inning.number}</th>
              ))}
              <th style={{ borderLeft: "1px grey solid" }}>R</th>
              <th>H</th>
              <th>E</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td key={`td-${live_board.inning}-away-name`}>
                <strong>{live_board.away_name}</strong>
              </td>
              {innings.map((inning) =>
                inning.number <= live_board.inning ? (
                  <td key={`away-runs-${inning.number}`}>
                    <span>{inning.away_runs || 0}</span>
                  </td>
                ) : (
                  <td key={`td-${live_board.inning}-${inning.number}-away`}></td>
                )
              )}
              <td key={`td-${live_board.inning}-away-score`}>
                <strong>{live_board.away_score}</strong>
              </td>
              <td key={`td-${live_board.inning}-away-hits`}>{live_board.away_hits}</td>
              <td key={`td-${live_board.inning}-away-errors`}>{live_board.away_errors}</td>
            </tr>
            <tr>
              <td key={`td-${live_board.inning}-home-name`}>
                <strong>{live_board.home_name}</strong>
              </td>
              {innings.map((inning) =>
                inning.number <= live_board.inning ? (
                  <td key={`home-runs-${inning.number}`}>
                    <span>{inning.home_runs || 0}</span>
                  </td>
                ) : (
                  <td key={`td-${live_board.inning}-${inning.number}-home`}></td>
                )
              )}
              <td key={`td-${live_board.inning}-home-score`}>
                <strong>{live_board.home_score}</strong>
              </td>
              <td key={`td-${live_board.inning}-home-hits`}>{live_board.home_hits}</td>
              <td key={`td-${live_board.inning}-home-errors`}>{live_board.home_errors}</td>
            </tr>
          </tbody>
        </Table>
        <div className="scoreboard-container">
          {!live_board.finished ? (
            <div className="live-scoreboard-stats">
              <div className="canvas-wrapper">
                <GameCanvas
                  drawFunc={showOutsCanvas}
                  data={live_board}
                  width="100"
                  height="100"
                />
                <div className="ballsandstrikes">
                  <strong>
                    {live_board.balls}-{live_board.strikes}
                  </strong>
                </div>
              </div>
              <div className="players-profiles-container">
                <div className="profiles">
                  <div className="profile-image">
                    {sboard.pitcher.headshot ? (
                      <ProfileIcon imageUrl={sboard.pitcher.headshot} />
                    ) : (
                      <FlameIcon />
                    )}
                  </div>
                  <div className="profile-data">
                    <span className="role">PITCHING</span>
                    <span className="name">{live_board.pitcher_name}</span>
                    <div className="stats">
                      <span>{sboard.pitcher.stats.ip} IP</span>&nbsp;
                      {sboard.pitcher.stats.h > 1 ? (
                        <span>{sboard.pitcher.stats.h} H</span>
                      ) : null}
                      &nbsp;
                      {sboard.pitcher.stats.so > 1 ? (
                        <span>{sboard.pitcher.stats.so} SO</span>
                      ) : null}
                      &nbsp;
                      {sboard.pitcher.stats.bb > 1 ? (
                        <span>{sboard.pitcher.stats.bb} BB</span>
                      ) : null}
                      &nbsp;
                      <span>
                        B:{sboard.pitcher.stats.balls}&nbsp; S:
                        {sboard.pitcher.stats.strikes}&nbsp; TP:
                        {sboard.pitcher.stats.pitches}&nbsp;
                      </span>
                    </div>
                  </div>
                </div>
                <div className="profiles">
                  <div className="profile-image">
                    {sboard.hitter.headshot ? (
                      <ProfileIcon imageUrl={sboard.hitter.headshot} />
                    ) : (
                      <FlameIcon />
                    )}
                  </div>
                  <div className="profile-data">
                    <span className="role">Hitting</span>
                    <span className="name">
                      #{live_board.hitter_number}
                      {live_board.hitter_name}
                    </span>
                    <div className="stats">
                      <span>
                        {sboard.hitter.stats.h}-{sboard.hitter.stats.ab}
                      </span>
                      {sboard.hitter.stats.db > 1 ? (
                        <span>{sboard.hitter.stats.db} 2B</span>
                      ) : null}
                      {sboard.hitter.stats.trp > 1 ? (
                        <span>{sboard.hitter.stats.trp} 3B</span>
                      ) : null}
                      {sboard.hitter.stats.hr > 1 ? (
                        <span>{sboard.hitter.stats.hr} HR</span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {live_board.finished ? (
            <div className="finished-scoreboard-stats">
              <div>
                <strong>&nbsp;Winning pitcher:&nbsp;</strong>
                {finals.winning_pitcher.name}
              </div>
              <div>
                <strong>&nbsp;Losing pitcher:&nbsp;</strong> {finals.losing_pitcher.name}
              </div>
              {finals.save ? (
                <div>
                  <strong>&nbsp;S</strong> {finals.save.name}
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Scoreboard;
