import { call, takeEvery, put, race, select, take } from "redux-saga/effects";

import {
  refreshTokensSuccess,
  refreshTokensStart,
  refreshTokensFailure,
} from "./user/user.actions";

import { selectTokens } from "./user/user.selectors";

const ignoreActionTypes = ["REFRESH_TOKENS"];

function monitorableAction(action) {
  return (
    action.type.includes("GET_") &&
    action.type.includes("_START") &&
    ignoreActionTypes.every((fragment) => !action.type.includes(fragment))
  );
}

function identifyAction(action) {
  return action.type.split("_").slice(0, -1).join("_");
}
function getSuccessType(action) {
  return `${identifyAction(action)}_SUCCESS`;
}
function getFailType(action) {
  return `${identifyAction(action)}_FAILURE`;
}

function* runRefreshTokens() {
  const { refresh_token } = yield select(selectTokens);
  yield put(refreshTokensStart(refresh_token));

  const { success } = yield race({
    success: take(refreshTokensSuccess().type),
    fail: take(refreshTokensFailure().type),
  });

  if (success) {
    return true;
  } else {
    console.log("token refresh failed, we need to re-login");
    return false;
  }
}

function* needRefresh() {
  const { expires_at } = yield select(selectTokens);
  const currentTime = new Date().getTime();
  const tokenExpireTime = new Date(expires_at).getTime();
  console.log(
    "Check token expire",
    expires_at,
    currentTime >= tokenExpireTime - 1 * 60 * 60 * 1000
  );
  return currentTime >= tokenExpireTime - 1 * 60 * 60 * 1000;
}

function* monitor(monitoredAction) {
  var isRefreshed = false;
  console.log(
    "started monitoring",
    monitoredAction.type,
    monitoredAction.payload
  );

  const isRefreshNeeded = yield call(needRefresh);
  console.log(isRefreshNeeded);
  // Run refresh token one hour before expiry time (comparison in ms)
  if (isRefreshNeeded) {
    isRefreshed = yield runRefreshTokens();
    if (isRefreshed) {
      yield put(monitoredAction);
    }
  }

  const { fail } = yield race({
    success: take(getSuccessType(monitoredAction)),
    fail: take(getFailType(monitoredAction)),
  });

  if (
    fail &&
    fail.payload &&
    (fail.payload.status === 401 || fail.payload.status === 403)
  ) {
    console.log("detected 401/403, refreshing token", fail.payload.tokens);
    yield runRefreshTokens();
    yield put(monitoredAction);
  }

  console.log("monitoring", monitoredAction.type, "finished");
}

export function* onMonitor() {
  yield takeEvery(monitorableAction, monitor);
}
