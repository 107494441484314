import GameActionTypes from "./game.types";

const INITIAL_STATE = {
  // App game data - load first
  appGameData: null,
  appGameDataError: null,
  // Set current game
  currentGame: null,
  // Get current game data - load second
  currentGameData: null,
  currentGameDataError: null,
  // Set current livestreams for games list
  currentLivestreams: null,
  // Player/game information - load in case of player page routing
  playerPage: null,
  playerPageError: null,
  // Player page playlist - loaded in case of successfull player page load
  playerPagePlaylist: null,
  playerPagePlaylistError: null,

  // Player page loaded flag
  playerPageIsLoading: null,
  // Set current game highlights playlist URL
  currentPlaylistURL: null,
  // Contents of any playlist - set by passing URL to load
  currentPlaylist: null,
  currentPlaylistError: null,
  // Current play by play game data
  currentGamePBP: null,
  currentGamePBPError: null,
  // Playlist play by play 
  playlistPBP: null,
  playlistPBPError: null,
  // current game highlights playlist
  playlistHighlights: null,
  playlistHighlightsError: null,
  // Video player control 
  currentPlayingVideo: null,
  nextPlayingVideo: null,
  // Box game stats tab content
  box: null,
  boxError: null,

  videoIsPlaying: false,
  videoTagsCloud: [],
  videoTagsActive: [],
};

const gameReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GameActionTypes.GET_APP_GAME_DATA_SUCCESS:
      return {
        ...state,
        appGameData: action.payload,
        appGameDataError: null,
      };
    case GameActionTypes.GET_APP_GAME_DATA_FAILURE:
      return {
        ...state,
        appGameData: null,
        appGameDataError: action.payload,
      };
    case GameActionTypes.FETCH_CURRENT_GAME_DATA_SUCCESS:
      return {
        ...state,
        currentGameData: action.payload,
        currentGameDataError: null,
      };
    case GameActionTypes.FETCH_CURRENT_GAME_DATA_FAILURE:
      return {
        ...state,
        currentGameData: null,
        currentGameDataError: action.payload,
      };
    case GameActionTypes.SET_CURRENT_GAME:
      return {
        ...state,
        currentGame: action.payload,
      };
    case GameActionTypes.SET_CURRENT_LIVESTREAMS:
      return {
        ...state,
        currentLivestreams: action.payload,
      };
    case GameActionTypes.GET_PLAYER_PAGE_START:
      return {
        ...state,
        playerPage: null,
        playerPageError: null
      }
    case GameActionTypes.GET_PLAYER_PAGE_SUCCESS:
      return {
        ...state,
        playerPage: action.payload,
        playerPageError: null,
      };
    case GameActionTypes.GET_PLAYER_PAGE_FAILURE:
      return {
        ...state,
        playerPage: null,
        playerPageError: action.payload,
      };
    case GameActionTypes.FETCH_PLAYER_PAGE_PLAYLIST_SUCCESS:
      return {
        ...state,
        playerPagePlaylist: action.payload,
        playerPagePlaylistError: null
      }
    case GameActionTypes.FETCH_PLAYER_PAGE_PLAYLIST_FAILURE:
      return {
        ...state,
        playerPagePlaylist: null,
        playerPagePlaylistError: action.payload
      }
    case GameActionTypes.SET_PLAYER_PAGE_LOADING_START:
      return {
        ...state,
        playerPageIsLoading: true
      }
    case GameActionTypes.SET_PLAYER_PAGE_LOADING_SUCCESS:
      return {
        ...state,
        playerPageIsLoading: false
      }
    case GameActionTypes.FETCH_HIGHLIGHTS_PLAYLIST_SUCCESS:
      return {
        ...state,
        playlistHighlights: action.payload,
        playlistHighlightsError: null,
      };
    case GameActionTypes.FETCH_HIGHLIGHTS_PLAYLIST_FAILURE:
      return {
        ...state,
        playlistHighlights: null,
        playlistHighlightsError: action.payload,
      };
    case GameActionTypes.FETCH_CURRENT_PBP_GAME_SUCCESS:
      return {
        ...state,
        currentGamePBP: action.payload,
        currentGamePBPError: null,
      };
    case GameActionTypes.FETCH_CURRENT_PBP_GAME_FAILURE:
      return {
        ...state,
        currentGamePBP: null,
        currentGamePBPError: action.payload,
      };
    case GameActionTypes.FETCH_PBP_PLAYLIST_SUCCESS:
      return {
        ...state,
        playlistPBP: action.payload,
        playlistPBPError: null
      };
    case GameActionTypes.FETCH_PBP_PLAYLIST_FAILURE:
      return {
        ...state,
        playlistPBP: null,
        playlistPBPError: action.payload
      };  
    case GameActionTypes.SET_PLAYLIST_HIGHLIGHTS:
      return {
        ...state,
        playlistHighlights: action.payload,
      };

    case GameActionTypes.SET_CURRENT_PLAYING_VIDEO:
      return {
        ...state,
        currentPlayingVideo: action.payload,
      };
    case GameActionTypes.SET_NEXT_PLAYING_VIDEO:
      return {
        ...state,
        nextPlayingVideo: action.payload,
      };
    case GameActionTypes.FETCH_BOX_START:
      return {
        ...state,
        box: null,
        boxError: null
      }
    case GameActionTypes.FETCH_BOX_SUCCESS:
      return {
        ...state,
        box: action.payload,
        boxError: null
      }
    case GameActionTypes.FETCH_BOX_FAILURE:
      return {
        ...state,
        box: null,
        boxError: action.payload
      }
    // TODO: Refactor down from here
    case GameActionTypes.SET_BOX:
      return {
        ...state,
        box: action.payload,
      };
    case GameActionTypes.SET_CURRENT_PLAYLIST:
      return {
        ...state,
        currentPlaylist: action.payload,
      };
    
    case GameActionTypes.SET_CURRENT_GAME_PBP:
      return {
        ...state,
        currentGamePBP: action.payload,
      };
    
    case GameActionTypes.SET_VIDEO_PLAYING:
      return {
        ...state,
        videoIsPlaying: action.payload,
      };
    case GameActionTypes.SET_VIDEO_TAGS_CLOUD:
      return {
        ...state,
        videoTagsCloud: action.payload,
      };
    case GameActionTypes.SET_VIDEO_TAGS_ACTIVE:
      return {
        ...state,
        videoTagsActive: action.payload,
      };
    default:
      return state;
  }
};

export default gameReducer;
