import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { setCurrentGame } from "../../redux/game/game.actions";

import GamePreviewIcon from "../game-preview-icon/game-preview-icon.component";

import "./games-list.styles.scss";

const GamesList = ({ games }) => {
    const dispatch = useDispatch();
    const currentGame = useSelector(state => state.game.currentGame);

  return games !== null ? (
    <div className="games-container">
      {games.map((game) => (
        <div
          key={game.game_id}
          className={`${ game.game_id === currentGame ? 'selected': null } game-container`}
          onClick={() => {
            dispatch(setCurrentGame(game.game_id));
          }}
        >
          <GamePreviewIcon key={game.game_id} game={game} />
        </div>
      ))}
    </div>
  ) : null;
};

export default GamesList;
