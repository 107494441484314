const GameActionTypes = {
  GET_APP_GAME_DATA_START: "GET_APP_GAME_DATA_START",
  GET_APP_GAME_DATA_SUCCESS: "GET_APP_GAME_DATA_SUCCESS",
  GET_APP_GAME_DATA_FAILURE: "GET_APP_GAME_DATA_FAILURE",

  FETCH_CURRENT_GAME_DATA_START: 'FETCH_CURRENT_GAME_DATA_START',
  FETCH_CURRENT_GAME_DATA_SUCCESS: 'FETCH_CURRENT_GAME_DATA_SUCCESS',
  FETCH_CURRENT_GAME_DATA_FAILURE: 'FETCH_CURRENT_GAME_DATA_FAILURE',

  GET_PLAYER_PAGE_START: 'GET_PLAYER_PAGE_START',
  GET_PLAYER_PAGE_SUCCESS: 'GET_PLAYER_PAGE_SUCCESS',
  GET_PLAYER_PAGE_FAILURE: 'GET_PLAYER_PAGE_FAILURE', 

  FETCH_PLAYER_PAGE_PLAYLIST_START: 'FETCH_PLAYER_PAGE_PLAYLIST_START',
  FETCH_PLAYER_PAGE_PLAYLIST_SUCCESS: 'FETCH_PLAYER_PAGE_PLAYLIST_SUCCESS',
  FETCH_PLAYER_PAGE_PLAYLIST_FAILURE: 'FETCH_PLAYER_PAGE_PLAYLIST_FAILURE',

  SET_PLAYER_PAGE_LOADING_START: 'SET_PLAYER_PAGE_LOADING_START',
  SET_PLAYER_PAGE_LOADING_SUCCESS: 'SET_PLAYER_PAGE_LOADING_SUCCESS',
  SET_PLAYER_PAGE_LOADING_FAILURE: 'SET_PLAYER_PAGE_LOADING_FAILURE',

  FETCH_HIGHLIGHTS_PLAYLIST_START: 'FETCH_HIGHLIGHTS_PLAYLIST_START',
  FETCH_HIGHLIGHTS_PLAYLIST_SUCCESS: 'FETCH_HIGHLIGHTS_PLAYLIST_SUCCESS',
  FETCH_HIGHLIGHTS_PLAYLIST_FAILURE: 'FETCH_HIGHLIGHTS_PLAYLIST_FAILURE',
  
  FETCH_CURRENT_PBP_GAME_START: 'FETCH_CURRENT_PBP_GAME_START',
  FETCH_CURRENT_PBP_GAME_SUCCESS: 'FETCH_CURRENT_PBP_GAME_SUCCESS',
  FETCH_CURRENT_PBP_GAME_FAILURE: 'FETCH_CURRENT_PBP_GAME_FAILURE',

  FETCH_PBP_PLAYLIST_START: 'FETCH_PBP_PLAYLIST_START',
  FETCH_PBP_PLAYLIST_SUCCESS: 'FETCH_PBP_PLAYLIST_SUCCESS',
  FETCH_PBP_PLAYLIST_FAILURE: 'FETCH_PBP_PLAYLIST_FAILURE', 

  FETCH_BOX_START: 'FETCH_BOX_START',
  FETCH_BOX_SUCCESS: 'FETCH_BOX_SUCCESS',
  FETCH_BOX_FAILURE: 'FETCH_BOX_FAILURE',
  SET_BOX: "SET_BOX",

  /* CURRENT VALUES SETTERS */

  SET_CURRENT_GAME: "SET_CURRENT_GAME",
  SET_CURRENT_PLAYLIST: "SET_CURRENT_PLAYLIST",
  SET_CURRENT_GAME_DATA: "SET_CURRENT_GAME_DATA",
  SET_CURRENT_LIVESTREAMS: "SET_CURRENT_LIVESTREAMS",
  SET_CURRENT_PLAYING_VIDEO: "SET_CURRENT_PLAYING_VIDEO",
  SET_NEXT_PLAYING_VIDEO: 'SET_NEXT_PLAYING_VIDEO',
  SET_VIDEO_TAGS_CLOUD: "SET_VIDEO_TAGS_CLOUD",
  SET_VIDEO_TAGS_ACTIVE: "SET_VIDEO_TAGS_ACTIVE",
  
  
  
  SET_VIDEO_PLAYING: "SET_VIDEO_PLAYING",
  
  
};

export default GameActionTypes;
