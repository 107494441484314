import React, { useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import { Redirect } from 'react-router-dom';


import { Card, Form, Input, Button, Error } from './AuthForms';

import { loginStart } from '../../redux/user/user.actions';



function Login() {
  const isError = useSelector(state => state.user.error);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  
  const dispatch = useDispatch();

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(loginStart({ userName, password}));
  }

  return (
    <Card>
      <Form>
        <Input
          type="username"
          value={userName}
          onChange={e => {
            setUserName(e.target.value);
          }}
          placeholder="email"
        />
        <Input
          type="password"
          value={password}
          onChange={e => {
            setPassword(e.target.value);
          }}
          placeholder="password"
        />
        <Button onClick={handleSubmit}>Sign In</Button>
      </Form>
        { isError &&<Error>The username or password provided were incorrect!</Error> }
    </Card>
  );
}

export default Login;
