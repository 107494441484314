import React from "react";

import ReactPlayer from "react-player";

import { useDispatch, useSelector } from "react-redux";

import {
  setCurrentPlayingVideo,
  setVideoPlaylistIndex,
  setVideoIsPlaying,
} from "../../redux/game/game.actions.js";

import { selectCurrentPlayingVideo, selectNextPlayingVideo} from '../../redux/game/game.selectors';

const VideoPlayer = () => {
  const videoIsPlaying = useSelector((state) => state.game.videoIsPlaying);
  const currentPlayingVideo = useSelector(selectCurrentPlayingVideo);
  const nextPlayingVideo = useSelector(selectNextPlayingVideo);

  const dispatch = useDispatch();

  const handleOnEnded = () => {
    if (nextPlayingVideo) {
      dispatch(setCurrentPlayingVideo(nextPlayingVideo));
      dispatch(setVideoIsPlaying(true));
    } else {
      // just stop playing
      // dispatch(setCurrentPlayingVideo(null));
      dispatch(setVideoIsPlaying(false));
    }
  };

  const handleOnPlay = () => {
    dispatch(setVideoIsPlaying(true));
  };

  const handleOnPause = () => {
    dispatch(setVideoIsPlaying(false));
  };

  const playerOptions = {
    url: currentPlayingVideo ? currentPlayingVideo.url: null,
    controls: true,
    width: "100%",
    height: "100%",
    onEnded: handleOnEnded,
    onPause: handleOnPause,
    playing: videoIsPlaying,
    onPlay: handleOnPlay,
  };

  return (
    <div className="player">
      <ReactPlayer {...playerOptions} />
    </div>
  );
};

export default VideoPlayer;
