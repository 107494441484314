import React from "react";
import { withRouter } from 'react-router';

import VideoPreviewImage from "../video-preview/video-preview.component";

import "./video-list-item.styles.scss";

const VideoListItem = ({ item, selected = false, history, match, staticContext, ...props }) => {
  return (
    <div {...props} className={`${selected ? "selected" : ""} video-list-item`}>
      <VideoPreviewImage imageUrl={item.thumbnail} />
      <div className="video-list-item-details">
        <h3 className="title">{item.title}</h3>
        <span className='details'>{item.details}</span>
        {item.players ? (
          <div className="player-info-container">
            {item.players.map((player) => (
              <span onClick={(e) => {
                e.stopPropagation(); 
                history.push(`/games/${match.params.appId}/player-g/${player.id}/${player.gid}`)
              }}
                key={`video-list-player-${player.gid}-${player.id}`}
                className="player-info"
              >
                {player.type}&nbsp;:&nbsp;{player.name}
              </span>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default withRouter(VideoListItem);
