import React from "react";
import { withRouter } from "react-router";

import { useSelector } from "react-redux";

import "./video-details.styles.scss";

const VideoDetails = ({ history, match }) => {
  const currentPlayingVideo = useSelector(
    (state) => state.game.currentPlayingVideo
  );
  return currentPlayingVideo ? (
    <div className="current-video-info">
      {currentPlayingVideo.title ? (
        <h2 className="title">{currentPlayingVideo.title}</h2>
      ) : null}
      {currentPlayingVideo.details ? (
        <span className="details">{currentPlayingVideo.details}</span>
      ) : null}
      {currentPlayingVideo.players ? (
        <div className="player-info-container">
          {currentPlayingVideo.players.map((player) => (
            <span
              onClick={() =>
                history.push(`${match.url}/player-g/${player.id}/${player.gid}`)
              }
              key={`video-preview-player-${player.gid}-${player.id}`}
              className="player-info"
            >
              {player.type} : {player.name}
            </span>
          ))}
        </div>
      ) : null}
    </div>
  ) : null;
};

export default withRouter(VideoDetails);
