import axios from "axios";
import qs from "querystring";

import { takeEvery, takeLatest, put, all, call, race, take } from "redux-saga/effects";

import {
  loginSuccess,
  loginFailure,
  refreshTokensSuccess,
  refreshTokensStart,
  refreshTokensFailure,
} from "./user.actions";
import UserActionTypes from "./user.types";

const CLIENT_ID = "An931tYhtpvu6WRWVXhcxAKy8OfvLhP8jWnWRVOm";
const CLIENT_SEC =
  "RD0mFUNOB8wrGMFSrUkE2CrpGgCkjcqmhMpfIGIvQgDNnxo7c1cQkYc4tEJZDfSEfmqcrMIyyukLX6nYk9teOSaZI388nuows2x7k3CMRnwKvJJ1DkUpDG85oAVhpFtb";
const TOKEN_URL = "https://pitchaware.com/o/token/";

export function* login({ payload: { userName, password } }) {
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  try {
    var currentDate = new Date();
    const result = yield axios.post(
      TOKEN_URL,
      qs.stringify({
        username: userName,
        password: password,
        grant_type: "password",
        client_id: CLIENT_ID,
        client_secret: CLIENT_SEC,
      }),
      config
    );

    if (result.status === 200) {
      currentDate.setSeconds(currentDate.getSeconds() + result.data.expires_in);
      result.data = {
        ...result.data,
        expires_at: currentDate,
      };
      yield put(loginSuccess(result.data));
    } else {
      yield put(loginFailure(result.data));
    }
  } catch (e) {
    yield put(loginFailure(e));
  }
}

export function* refreshTokens({'payload': refreshToken}) {
  var currentDate = new Date();
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  try {
    const result = yield axios.post(
      TOKEN_URL,
      qs.stringify({
        refresh_token: refreshToken,
        grant_type: "refresh_token",
        client_id: CLIENT_ID,
        client_secret: CLIENT_SEC,
      }),
      config
    );

    if (result.status === 200) {
      currentDate.setSeconds(currentDate.getSeconds() + result.data.expires_in);
      result.data = {
        ...result.data,
        expires_at: currentDate,
      };
      yield put(refreshTokensSuccess(result.data));
    } else {
      yield put(refreshTokensFailure(result.data));
    }
  } catch (e) {
    yield put(refreshTokensFailure(e));
  }
}




export function* onLoginStart() {
  yield takeLatest(UserActionTypes.LOGIN_START, login);
}

export function* onRefreshTokensStart() {
  yield takeLatest(UserActionTypes.REFRESH_TOKENS_START, refreshTokens);
}

export function* userSagas() {
  yield all([call(onLoginStart), call(onRefreshTokensStart)]);
}
