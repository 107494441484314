import React from "react";
import { useSelector } from 'react-redux';

import { Col, Nav, Row, Tab, Tabs, Table } from "react-bootstrap";

import PBP from "../pbp/pbp.component";
import Box from "../box/box.component";

import GameFieldCanvas from "../game-field-canvas/game-field-canvas.component";
import GamePlateCanvas from "../game-plate-canvas/game-plate-canvas.component";
import GameLastPlayFieldCanvas from "../game-last-play-field-canvas/game-last-play-field-canvas.component";
import VideoHighlights from '../video-highlights/video-highlights.component';

import hitterLefty from "../../assets/hitter-lefty.png";
import hitterRighty from "../../assets/hitter-righty.png";

import "./gamestats.styles.scss";

const Gamestats = () => {
  const currentGameData = useSelector(state => state.game.currentGameData)
  return currentGameData ? (
    <Tabs defaultActiveKey="live" id="section-e" className="nav-justified">
      {currentGameData.live_board.finished ? null : (
        <Tab eventKey="live" title="LIVE">
          <Tab.Container id="live-tabs" defaultActiveKey="live-plate">
            <Row>
              <Col>
                <Nav variant="tabs" className="nav-justified">
                  <Nav.Item>
                    <Nav.Link eventKey="live-plate" className="rounded-0">
                      PLATE
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="live-field" className="rounded-0">
                      FIELD
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="live-last-play" className="rounded-0">
                      LAST PLAY
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
            </Row>
            <Row>
              <Col>
                <Tab.Content>
                  <Tab.Pane eventKey="live-plate" className="rounded-0">
                    <div className="live-plate-content">
                      {currentGameData.live_board.hitter_righty ? (
                        <div className="hitter">
                          <img src={hitterRighty} alt="hitter-righty" />
                        </div>
                      ) : null}
                      <div className="canvas-container">
                        <GamePlateCanvas
                          pitches={currentGameData.pitches}
                          width="668"
                          height="300"
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                      {currentGameData.live_board.hitter_lefty ? (
                        <div className="hitter">
                          <img src={hitterLefty} alt="hitter-lefty" />
                        </div>
                      ) : null}
                      <Table>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Count</th>
                            <th>Pitch</th>
                            <th>Result</th>
                            <th>Velo (mph)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentGameData.pitches.map((pitch) => (
                            <tr>
                              <td>{pitch.numb}</td>
                              <td>
                                {pitch.balls}-{pitch.strikes}
                              </td>
                              <td>{pitch.pitch_type}</td>
                              <td>{pitch.result_str}</td>
                              <td>{pitch.velo}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="live-field" className="rounded-0">
                    <div className="canvas-container ">
                      <GameFieldCanvas
                        live_board={currentGameData.live_board}
                        field={currentGameData.field}
                        width="800"
                        height="700"
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="live-last-play" className="rounded-0">
                    <div className="last-play-content">
                      <Table>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Count</th>
                            <th>Pitch</th>
                            <th>Result</th>
                            <th>Velo (mph)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentGameData.last_play.pitches.map((pitch) => (
                            <tr>
                              <td>{pitch.numb}</td>
                              <td>
                                {pitch.balls}-{pitch.strikes}
                              </td>
                              <td>{pitch.pitch_type}</td>
                              <td>{pitch.result_str}</td>
                              <td>{pitch.velo}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <GamePlateCanvas
                        pitches={currentGameData.last_play.pitches}
                        withBackground={false}
                        width="150"
                        height="200"
                      />
                      <GameLastPlayFieldCanvas
                        last_play={currentGameData.last_play}
                        width="320"
                        height="300"
                      />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Tab>
      )}
      <Tab eventKey="box" title="BOX">
        <Box />
      </Tab>
      <Tab eventKey="plays" title="PLAYS">
        <PBP key='plays-pbp' />
      </Tab>
      <Tab eventKey="highlights" title="HIGHLIGHTS">
        <VideoHighlights />
      </Tab>
    </Tabs>
  ) : null;
};

export default Gamestats;
