import React from "react";
import { BrowserRouter as Router, Link, Route, Redirect } from "react-router-dom";
import PrivateRoute from './PrivateRoute';
import Home from './pages/home/home.component';
import Player from './pages/Player';
import Login from './pages/login/login.component';

import './App.scss'

function App(props) {

  return (
      <Router>
        <div>
          <Route path="/games/:appId" component={Home} />
          <Route exact path="/login" component={Login} />
        </div>
      </Router>
  );
}

export default App;
