import React, { useState, useEffect} from "react";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";

import { Table, Tabs, Tab } from "react-bootstrap";

import {
  setVideoIsPlaying,
  setCurrentPlayingVideo,
  setCurrentPlaylist,
} from "../../redux/game/game.actions";

import { selectPlayerPagePlaylist } from "../../redux/game/game.selectors";

import VideoPreviewImage from "../video-preview/video-preview.component";

import "./player-ab-tables.styles.scss";

const PlayerABTables = ({ data, idx, match }) => {
  const dispatch = useDispatch();
  const [pl, setPL] = useState(null);
  const playerPlaylist = useSelector(selectPlayerPagePlaylist);
  useEffect(() => {
    setPL(playerPlaylist);
  }, [playerPlaylist])
  return (
    <Table striped hover bordered>
      <thead className="thead-dark">
        <tr>
          {data.headers.map((header, headIdx) => (
            <th key={`head-ab-${idx}-${headIdx}`}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.rows.map((row, index) => {
          return (
            <tr key={`row-ab-${idx}-${index}`}>
              {row.map((column, colIdx) => {
                switch (column.type) {
                  case "video":
                    return (
                      <td key={`col-ab-video-${idx}-${index}-${colIdx}`}>
                        {column.video.length < 1 ? null : (
                          <VideoPreviewImage
                            imageUrl={column.video[0].video_info.thumbnail}
                            onClick={() => {
                              dispatch(setCurrentPlaylist(pl));
                              dispatch(
                                setCurrentPlayingVideo(
                                  {
                                    url: column.video[0].video_info.url,
                                    title: column.video[0].title,
                                    details: column.video[0].description,
                                  }
                                )
                              );
                              dispatch(setVideoIsPlaying(true));
                            }}
                          />
                        )}
                      </td>
                    );
                  case "player_game":
                    return (
                      <td key={`col-ab-${idx}-${index}-${colIdx}`}>
                        <Link
                          to={`/games/${match.params.appId}/player-g/${column.pid}/${column.card_id}`}
                        >
                          {column.display}
                        </Link>
                      </td>
                    );
                  default:
                    return (
                      <td key={`col-ab-${idx}-${index}-${colIdx}`}>
                        {column.display}
                      </td>
                    );
                }
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default withRouter(PlayerABTables);
