import React, { useEffect, useRef } from 'react';

// import HeatmapEmptyImg from '../../assets/heatmap.jpg';
// import HeatmapImg from '../../assets/BaseballPlate.png';

import { drawHeatmap } from '../../utils/draw';

import './game-heatmap-canvas.styles.scss';


const HeatmapCanvas = ({ data }) => {
    const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    
    drawHeatmap(container, data);
  }, [drawHeatmap, data]);
    
  return (
    <div className='heatmap-container'>
        <div className='canvas-container' ref={containerRef} >
        </div>
    </div>
)}

export default HeatmapCanvas;