import GameActionTypes from "./game.types";

/* APP GAME DATA */
export const getAppGameDataStart = (appId) => ({
  type: GameActionTypes.GET_APP_GAME_DATA_START,
  payload: appId,
});

export const getAppGameDataSuccess = (data) => ({
  type: GameActionTypes.GET_APP_GAME_DATA_SUCCESS,
  payload: data,
});

export const getAppGameDataFailure = (error) => ({
  type: GameActionTypes.GET_APP_GAME_DATA_FAILURE,
  payload: error,
});

/* CURRENT GAME DATA */
export const setCurrentGame = (game) => ({
  type: GameActionTypes.SET_CURRENT_GAME,
  payload: game,
});

export const fetchCurrentGameDataStart = (currentGameId) => ({
  type: GameActionTypes.FETCH_CURRENT_GAME_DATA_START,
  payload: currentGameId,
});

export const fetchCurrentGameDataSuccess = (data) => ({
  type: GameActionTypes.FETCH_CURRENT_GAME_DATA_SUCCESS,
  payload: data,
});

export const fetchCurrentGameDataFailure = (error) => ({
  type: GameActionTypes.FETCH_CURRENT_GAME_DATA_FAILURE,
  payload: error,
});

/* PLAYER PAGE DATA */

export const getPlayerPageStart = (playerIdAndGameId) => ({
  type: GameActionTypes.GET_PLAYER_PAGE_START,
  payload: playerIdAndGameId,
});

export const getPlayerPageSuccess = (data) => ({
  type: GameActionTypes.GET_PLAYER_PAGE_SUCCESS,
  payload: data,
});

export const getPlayerPageFailure = (error) => ({
  type: GameActionTypes.GET_PLAYER_PAGE_FAILURE,
  payload: error,
});

export const fetchPlayerPagePlaylistStart = (url) => ({
  type: GameActionTypes.FETCH_PLAYER_PAGE_PLAYLIST_START,
  payload: url,
});

export const fetchPlayerPagePlaylistSuccess = (data) => ({
  type: GameActionTypes.FETCH_PLAYER_PAGE_PLAYLIST_SUCCESS,
  payload: data,
});

export const fetchPlayerPagePlaylistFailure = (error) => ({
  type: GameActionTypes.FETCH_PLAYER_PAGE_PLAYLIST_FAILURE,
  payload: error,
});

export const setPlayerPageLoadingStart = () => ({
  type: GameActionTypes.SET_PLAYER_PAGE_LOADING_START,
});

export const setPlayerPageLoadingSuccess = () => ({
  type: GameActionTypes.SET_PLAYER_PAGE_LOADING_SUCCESS,
});

export const setPlayerPageLoadingFailure = (error) => ({
  type: GameActionTypes.SET_PLAYER_PAGE_LOADING_FAILURE,
  payload: error,
});

/* HIGHLIGHTS PLAYLIST LOAD */

export const fetchHighlightsPlaylistStart = (playlistUrl) => ({
  type: GameActionTypes.FETCH_HIGHLIGHTS_PLAYLIST_START,
  payload: playlistUrl,
});

export const fetchHighlightsPlaylistSuccess = (data) => ({
  type: GameActionTypes.FETCH_HIGHLIGHTS_PLAYLIST_SUCCESS,
  payload: data,
});

export const fetchHighlightsPlaylistFailure = (error) => ({
  type: GameActionTypes.FETCH_HIGHLIGHTS_PLAYLIST_FAILURE,
  payload: error,
});

/* PLAY BY PLAY CURRENT GAME DATA */

export const fetchCurrentPBPGameStart = (currentGameId) => ({
  type: GameActionTypes.FETCH_CURRENT_PBP_GAME_START,
  payload: currentGameId,
});

export const fetchCurrentPBPGameSuccess = (data) => ({
  type: GameActionTypes.FETCH_CURRENT_PBP_GAME_SUCCESS,
  payload: data,
});

export const fetchCurrentPBPGameFailure = (error) => ({
  type: GameActionTypes.FETCH_CURRENT_PBP_GAME_FAILURE,
  payload: error,
});

export const fetchPBPPlaylistStart = (url) => ({
  type: GameActionTypes.FETCH_PBP_PLAYLIST_START,
  payload: url,
});

export const fetchPBPPlaylistSuccess = (data) => ({
  type: GameActionTypes.FETCH_PBP_PLAYLIST_SUCCESS,
  payload: data,
});

export const fetchPBPPlaylistFailure = (error) => ({
  type: GameActionTypes.FETCH_PBP_PLAYLIST_FAILURE,
  payload: error,
});

export const fetchBoxStart = (url) => ({
  type: GameActionTypes.FETCH_BOX_START,
  payload: url,
});

export const fetchBoxSuccess = (data) => ({
  type: GameActionTypes.FETCH_BOX_SUCCESS,
  payload: data,
});

export const fetchBoxFailure = (error) => ({
  type: GameActionTypes.FETCH_BOX_FAILURE,
  payload: error,
});

export const setBox = (box) => ({
  type: GameActionTypes.SET_BOX,
  payload: box,
});

export const setCurrentPlaylist = (playlist) => ({
  type: GameActionTypes.SET_CURRENT_PLAYLIST,
  payload: playlist,
});

export const setCurrentPlayingVideo = (video) => ({
  type: GameActionTypes.SET_CURRENT_PLAYING_VIDEO,
  payload: video,
});

export const setNextPlayingVideo = (video) => ({
  type: GameActionTypes.SET_NEXT_PLAYING_VIDEO,
  payload: video,
});

export const setCurrentGameData = (data) => ({
  type: GameActionTypes.SET_CURRENT_GAME_DATA,
  payload: data,
});

export const setCurrentLivestreams = (livestreams) => ({
  type: GameActionTypes.SET_CURRENT_LIVESTREAMS,
  payload: livestreams,
});

export const setVideoIsPlaying = (flag) => ({
  type: GameActionTypes.SET_VIDEO_PLAYING,
  payload: flag,
});

export const setVideoTagsCloud = (values) => ({
  type: GameActionTypes.SET_VIDEO_TAGS_CLOUD,
  payload: values,
});

export const setVideoTagsActive = (values) => ({
  type: GameActionTypes.SET_VIDEO_TAGS_ACTIVE,
  payload: values,
});
