const UserActionTypes = {
  SET_TOKENS: "SET_TOKENS",
  LOGIN_START: 'LOGIN_START',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  REFRESH_TOKENS_START: 'REFRESH_TOKENS_START',
  REFRESH_TOKENS_SUCCESS: 'REFRESH_TOKENS_SUCCESS',
  REFRESH_TOKENS_FAILURE: 'REFRESH_TOKENS_FAILURE',
  SET_CURRENT_USER: "SET_CURRENT_USER",
};

export default UserActionTypes;