import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  setCurrentGame,
  setCurrentLivestreams,
  setPlaylistPBPURL,
  getAppGameDataStart,
  fetchCurrentGameDataStart,
  fetchCurrentPlaylistStart,
  fetchCurrentPBPGameStart,
  fetchBoxStart,
} from "../../redux/game/game.actions.js";

import { Spinner } from "../../components/with-spinner/with-spinner.component";

import GamesList from "../../components/games-list/games-list.component";
import Gamestats from "../../components/gamestats/gamestats.component";
import VideoPlayer from "../../components/video-player/video-player.component";
import VideoDetails from "../../components/video-details/video-details.component";
import VideoList from "../../components/video-list/video-list.component";
import VideoTags from "../../components/video-tags/video-tags.component";
import Livestats from "../../components/livestats/livestats.component";
import Livestreams from "../../components/livestreams/livestreams.component";

import GamePlayerPage from "../game-player/game-player.component";

import "./home.styles.scss";

export const Home = ({ match }) => {
  const [isLoading, setIsLoading] = useState(true);

  const appId = match.params.appId;
  const appGameData = useSelector((state) => state.game.appGameData);
  const live_stream_access = true;
  const finished = false;
  const dispatch = useDispatch();
  const livestreams = useSelector((state) => state.game.currentLivestreams);
  const currentGameData = useSelector((state) => state.game.currentGameData);
  const currentGame = useSelector((state) => state.game.currentGame);
  const currentPlayingVideo = useSelector(
    (state) => state.game.currentPlayingVideo
  );

  useEffect(() => {
    if (appGameData) {
      //setIsLoading(false);
      dispatch(setCurrentGame(appGameData.game_hash));
      dispatch(
        setCurrentLivestreams(appGameData.event.current_livestream_games)
      );
    }
  }, [appGameData]);

  useEffect(() => {
    async function fetchAppGameData() {
      setIsLoading(true);
      dispatch(getAppGameDataStart(appId));
    }

    fetchAppGameData();
  }, [appId]);

  useEffect(() => {
    async function fetchCurrentGameData() {
      //setIsLoading(true);
      dispatch(fetchCurrentGameDataStart(currentGame));
      dispatch(fetchCurrentPBPGameStart(currentGame));
      dispatch(fetchBoxStart(currentGame));
    }

    if (currentGame) {
      fetchCurrentGameData();
    }
  }, [currentGame]);

  useEffect(() => {
    if (currentGameData) {
      setIsLoading(false);
    }
  }, [currentGameData]);

  return isLoading ? (
    <Spinner />
  ) : (
    <div className="home-container">
      <div>
        <div className="games">
          <GamesList games={livestreams} />
        </div>
        <div className="livestreams-player">
          <div className="livestreams">
            {live_stream_access || !finished ? <Livestreams /> : null}
          </div>
          <div className="player-container">
            <VideoPlayer />
            <VideoDetails />
            <VideoTags />
          </div>
        </div>
      </div>
      <div className="scrollable-container">
        <div className="scrollable two-thirds">
          <Livestats />
          <Switch>
            <Route exact path={`${match.path}/`} component={Gamestats} />
            <Route
              exact
              path={`${match.path}/player-g/:playerId/:gameId`}
              component={GamePlayerPage}
            />
          </Switch>
        </div>
        <div className="scrollable one-third">
          <VideoList />
        </div>
      </div>
    </div>
  );
};

export default withRouter(Home);
