import React, { useRef, useEffect } from "react";

import { drawZone } from "../../utils/draw.js";

import PlateImage from '../../assets/battersbox.png';

import "./game-plate-canvas.styles.scss";

const GamePlateCanvas = ({ pitches, withBackground=true, ...props }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    let img = null;
    if (withBackground) {
      img = PlateImage;
    }
    drawZone(context, canvas.width, canvas.height, pitches, img);
  }, [pitches, withBackground]);

  return <canvas ref={canvasRef} {...props} />;
};

export default GamePlateCanvas;
