import React, { useRef, useEffect } from "react";

import { drawLastPlayField } from "../../utils/draw.js";

import "./game-last-play-field-canvas.styles.scss";

const GameLastPlayFieldCanvas = ({ last_play , ...props }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    drawLastPlayField(context, canvas.width, canvas.height, last_play);
  }, [last_play]);

  return <canvas ref={canvasRef} {...props} />;
};

export default GameLastPlayFieldCanvas;
