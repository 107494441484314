import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Spinner } from "../with-spinner/with-spinner.component";

import VideoListItem from "../video-list-item/video-list-item.component";

import {
  setCurrentPlayingVideo,
  setVideoIsPlaying,
  setVideoPlaylistIndex,
  setNextPlayingVideo,
} from "../../redux/game/game.actions";

import {
  selectCurrentPlaylistWithErrorInfo,
  selectCurrentPlayingVideo,
} from "../../redux/game/game.selectors";

import "./video-list.styles.scss";

const VideoList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [videoIndex, setVideoIndex] = useState(0);
  const { currentPlaylist, currentPlaylistError } = useSelector(
    selectCurrentPlaylistWithErrorInfo
  );
  const currentPlayingVideo = useSelector(selectCurrentPlayingVideo);
  const currentVideoTagsActive = useSelector(
    (state) => state.game.videoTagsActive
  );
  const [showPlaylist, setShowPlaylist] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    if (currentPlaylist && currentPlaylist.videos) {
      if (currentVideoTagsActive.length > 0) {
        const filteredPlaylist = currentPlaylist.videos.filter((item) =>
          currentVideoTagsActive.every((tag) => item.tags.includes(tag))
        );
        setShowPlaylist(filteredPlaylist);
      } else {
        setShowPlaylist(currentPlaylist.videos);
      }
    } else {
      setShowPlaylist(null);
    }
    setIsLoading(false);
  }, [currentPlaylist, currentVideoTagsActive]);

  useEffect(() => {
    if (!!showPlaylist && !!currentPlayingVideo) {
      const currentIndex = showPlaylist.findIndex(
        (video) => video.url === currentPlayingVideo.url
      );
      setVideoIndex(currentIndex > -1 ? currentIndex : 0);
      if (showPlaylist[currentIndex + 1]) {
        dispatch(setNextPlayingVideo(showPlaylist[currentIndex + 1]));
      } else {
        dispatch(setNextPlayingVideo(null));
      }
    } 
    if (!!!showPlaylist) {
      dispatch(setNextPlayingVideo(null));
    }
  }, [showPlaylist, currentPlayingVideo]);

  // useEffect(() => {
  //   if (!!showPlaylist) {
  //     setIsLoading(false);

  //     // dispatch(setCurrentPlayingVideo())
  //   }
  //   if (!!currentPlaylistError) {
  //     console.log('We have an error', currentPlaylistError)
  //   }
  // });

  return isLoading ? (
    <Spinner />
  ) : currentPlaylistError ? (
    <div className="video-list error">
      Playlist load error: {currentPlaylistError.statusText}
    </div>
  ) : showPlaylist ? (
    <div className="video-list">
      {showPlaylist.map((item, idx) => (
        <VideoListItem
          item={item}
          key={item.video_id}
          selected={idx === videoIndex}
          onClick={() => {
            dispatch(setCurrentPlayingVideo(item));
            dispatch(setVideoIsPlaying(true));
            // dispatch(setVideoPlaylistIndex(idx));
          }}
        />
      ))}
    </div>
  ) : null;
};

export default VideoList;
