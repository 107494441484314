import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { Table, Tabs, Tab, Nav, Row, Col } from "react-bootstrap";

import { Spinner } from '../with-spinner/with-spinner.component';

import { selectBoxData } from '../../redux/game/game.selectors';

import "./box.styles.scss";

const Box = ({ match }) => {
  const box = useSelector(selectBoxData);

  return box ? (
    <div className="box">
      <Tabs defaultActiveKey="box" id="box" className="nav-justified">
        <Tab eventKey="box" title="BOXSCORE">
          <Tab.Container id="box-tabs" defaultActiveKey="boxscore-opponent">
            <Row>
              <Col>
                <Nav variant="tabs" className="nav-justified">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="boxscore-opponent"
                      className="rounded-0"
                    >
                      {box.lineup.opponent.team_name}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="boxscore-team" className="rounded-0">
                      {box.lineup.team.team_name}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
            </Row>
            <Row>
              <Col>
                <Tab.Content>
                  <Tab.Pane eventKey="boxscore-opponent" className="rounded-0">
                    <Table striped bordered hover variant="dark">
                      <thead>
                        <tr>
                          <th className="col-6">HITTER</th>
                          <th>AB</th>
                          <th>R</th>
                          <th>H</th>
                          <th>RBI</th>
                          <th>BB</th>
                          <th>SO</th>
                          <th>LOB</th>
                          <th>AVG</th>
                        </tr>
                      </thead>
                      <tbody>
                        {box.away_hitting.map((row, idx) => (
                          <tr key={`box-away-hitting-${idx}`}>
                            <td className="col-6">
                              <Link
                                to={`/games/${match.params.appId}/player-g/${row.id}/${row.card_id}`}
                              >
                                #{row.numb} {row.name}
                              </Link>
                            </td>
                            <td>{row.ab}</td>
                            <td>{row.r}</td>
                            <td>{row.h}</td>
                            <td>{row.rbi}</td>
                            <td>{row.bb}</td>
                            <td>{row.so}</td>
                            <td>{row.lob}</td>
                            <td>{row.baa}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Table striped bordered hover variant="dark">
                      <thead>
                        <tr>
                          <th className="col-6">PITCHER</th>
                          <th>IP</th>
                          <th>H</th>
                          <th>R</th>
                          <th>ER</th>
                          <th>BB</th>
                          <th>SO</th>
                          <th>HR</th>
                          <th>ERA</th>
                        </tr>
                      </thead>
                      <tbody>
                        {box.away_pitching.map((row, idx) => (
                          <tr key={`box-away-pitching-${idx}`}>
                            <td className="col-6">
                              <Link
                                to={`/games/${match.params.appId}/player-g/${row.id}/${row.card_id}`}
                              >
                                #{row.numb} {row.name}
                              </Link>
                            </td>
                            <td>{row.ip}</td>
                            <td>{row.h}</td>
                            <td>{row.r}</td>
                            <td>{row.er}</td>
                            <td>{row.bb}</td>
                            <td>{row.so}</td>
                            <td>{row.hr}</td>
                            <td>{row.era}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Tab.Pane>
                  <Tab.Pane eventKey="boxscore-team" className="rounded-0">
                    <Table striped bordered hover variant="dark">
                      <thead>
                        <tr>
                          <th className="col-6">HITTER</th>
                          <th>AB</th>
                          <th>R</th>
                          <th>H</th>
                          <th>RBI</th>
                          <th>BB</th>
                          <th>SO</th>
                          <th>LOB</th>
                          <th>AVG</th>
                        </tr>
                      </thead>
                      <tbody>
                        {box.home_hitting.map((row, idx) => (
                          <tr key={`box-home-hitting-${idx}`}>
                            <td className="col-6">
                              <Link
                                to={`/games/${match.params.appId}/player-g/${row.id}/${row.card_id}`}
                              >
                                #{row.numb} {row.name}
                              </Link>
                            </td>
                            <td>{row.ab}</td>
                            <td>{row.r}</td>
                            <td>{row.h}</td>
                            <td>{row.rbi}</td>
                            <td>{row.bb}</td>
                            <td>{row.so}</td>
                            <td>{row.lob}</td>
                            <td>{row.baa}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Table striped bordered hover variant="dark">
                      <thead>
                        <tr>
                          <th className="col-6">PITCHER</th>
                          <th>IP</th>
                          <th>H</th>
                          <th>R</th>
                          <th>ER</th>
                          <th>BB</th>
                          <th>SO</th>
                          <th>HR</th>
                          <th>ERA</th>
                        </tr>
                      </thead>
                      <tbody>
                        {box.home_pitching.map((row, idx) => (
                          <tr key={`box-home-pitching-${idx}`}>
                            <td className="col-6">
                              <Link
                                to={`/games/${match.params.appId}/player-g/${row.id}/${row.card_id}`}
                              >
                                #{row.numb} {row.name}
                              </Link>
                            </td>
                            <td>{row.ip}</td>
                            <td>{row.h}</td>
                            <td>{row.r}</td>
                            <td>{row.er}</td>
                            <td>{row.bb}</td>
                            <td>{row.so}</td>
                            <td>{row.hr}</td>
                            <td>{row.era}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Tab>
        <Tab eventKey="lineup" title="LINEUP">
          <Tab.Container id="lineup-tabs" defaultActiveKey="lineup-opponent">
            <Row>
              <Col>
                <Nav variant="tabs" className="nav-justified">
                  <Nav.Item>
                    <Nav.Link eventKey="lineup-opponent" className="rounded-0">
                      {box.lineup.opponent.team_name}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="lineup-team" className="rounded-0">
                      {box.lineup.team.team_name}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
            </Row>
            <Row>
              <Col>
                <Tab.Content>
                  <Tab.Pane eventKey="lineup-opponent" className="rounded-0">
                    <Table striped bordered hover variant="dark">
                      <tbody>
                        {box.lineup.opponent.spots.map((spot, idx) => (
                          <tr key={`box-lineup-opponent-${idx}`}>
                            <td>{spot.pos}</td>
                            <td>{spot.field_pos}</td>
                            <td>{spot.name}</td>
                            <td>{spot.number}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Tab.Pane>
                  <Tab.Pane eventKey="lineup-team" className="rounded-0">
                    <Table striped bordered hover variant="dark">
                      <tbody>
                        {box.lineup.team.spots.map((spot, idx) => (
                          <tr key={`box-lineup-team-${idx}`}>
                            <td>{spot.pos}</td>
                            <td>{spot.field_pos}</td>
                            <td>{spot.name}</td>
                            <td>{spot.number}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Tab>
        <Tab eventKey="roster" title="ROSTER">
          <Tab.Container id="roster-tabs" defaultActiveKey="roster-opponent">
            <Row>
              <Col>
                <Nav variant="tabs" className="nav-justified">
                  <Nav.Item>
                    <Nav.Link eventKey="roster-opponent" className="rounded-0">
                      {box.lineup.opponent.team_name}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="roster-team" className="rounded-0">
                      {box.lineup.team.team_name}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
            </Row>
            <Row>
              <Col>
                <Tab.Content>
                  <Tab.Pane eventKey="roster-opponent" className="rounded-0">
                    <Table striped bordered hover variant="dark">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th className="col-4">Player</th>
                          <th>Pos</th>
                          <th>B/T</th>
                        </tr>
                      </thead>
                      <tbody>
                        {box.away_roster.map((player, idx) => (
                          <tr key={`box-roster-away-${idx}`}>
                            <td>{player.jersey_number}</td>
                            <td className="col-4">{player.full_name}</td>
                            <td>{player.position}</td>
                            <td>
                              {player.dex.hits}/{player.dex.arm}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Tab.Pane>
                  <Tab.Pane eventKey="roster-team" className="rounded-0">
                    <Table striped bordered hover variant="dark">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th className="col-4">Player</th>
                          <th>Pos</th>
                          <th>B/T</th>
                        </tr>
                      </thead>
                      <tbody>
                        {box.home_roster.map((player, idx) => (
                          <tr key={`box-roster-home-${idx}`}>
                            <td>{player.jersey_number}</td>
                            <td className="col-4">{player.full_name}</td>
                            <td>{player.position}</td>
                            <td>
                              {player.dex.hits}/{player.dex.arm}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Tab>
      </Tabs>
    </div>
  ) : <Spinner />;
};

export default withRouter(Box);
