import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Spinner } from '../with-spinner/with-spinner.component';
import VideoListItem from "../video-list-item/video-list-item.component";

import {
  setCurrentPlaylist,
  setCurrentPlayingVideo,
  setVideoIsPlaying,
} from "../../redux/game/game.actions";

import "./video-highlights.styles.scss";

const VideoHighlights = () => {
  const [isLoading, setIsLoading] = useState(true)
  const playlistHighlights = useSelector(state => state.game.playlistHighlights)
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!playlistHighlights) {
      setIsLoading(false);
    }
  }, [playlistHighlights])

  return isLoading ? <Spinner /> : (
    <div className="video-highlights">
      {playlistHighlights.videos.map((item, idx) => (
        <VideoListItem
          item={item}
          key={item.video_id}
          //selected={idx === videoPlaylistIndex}
          onClick={() => {
            dispatch(setCurrentPlaylist(playlistHighlights));
            dispatch(setCurrentPlayingVideo(item));
            dispatch(setVideoIsPlaying(true));
          }}
        />
      ))}
    </div>
  );
};

export default VideoHighlights;
